import { Box, Typography } from '@mui/material';
import React from 'react';
import CardMachine from './CardMachine';
import CardSkeleton from './CardSkeleton';
import useDataMachines from '../../hooks/useDataMahines';
import { useService } from '../../store/service.store';

const List = () => {
    const { data, isLoading } = useDataMachines();
    const { search } = useService();

    const filteredData = data?.filter((item) => {
        const searchTrimmed = search?.toLowerCase().trim() || '';
        const sku = item.product.sku?.toLowerCase() || '';
        const title = item.product.title?.toLowerCase() || '';
        const serialNumber = item.product.serialNumber?.toLowerCase() || '';
        return (
            sku.includes(searchTrimmed) ||
            title.includes(searchTrimmed) ||
            serialNumber.includes(searchTrimmed)
        );
    });

    return (
        <Box>
            <Box sx={{ padding: '10px' }}>
                <Typography variant="h5" color="#154D72" fontWeight={700} lineHeight="28px" fontSize="20px">
                    המכונות שלי
                </Typography>
                {filteredData && filteredData.length > 0 && (
                    <Typography variant="body2" color="#436F7C" fontSize="13px" fontWeight={400}>
                        לפתיחת קריאת שירות, לחצו על המכונה
                    </Typography>
                )}
            </Box>

            {isLoading ? (
                [...Array(5)].map((_, index) => <CardSkeleton key={index} />)
            ) : (
                filteredData?.map((item) => <CardMachine key={item.product.id} item={item} />)
            )}
        </Box>
    );
};

export default List;
