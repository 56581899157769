import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCart } from '../../store/cart.store';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { useArea } from '../../store/area.store';
import { useNotify } from '../../provider/NotifyProvider';
import useDataLines from '../../hooks/useDataLines';
import CustomCircularProgress from '../Common/Preloader';
import ModalWrapper from '../Modals/ModalWrapper';

const MyIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 10H3M16 2V6M8 2V6M10.5 14L12 13V18M10.75 18H13.25M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
            stroke="#2F4B6C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


const Calendar = () => {
    const [isAllow, setIsAllow] = useState(false)
    const { deliveryDate, setDeliveryDate, sendOrder, loading, cart,setCart } = useCart();
    const { data } = useDataLines();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { choosedArea } = useArea();
    const { onErrorNotify, onSuccessNotify } = useNotify();
    const [openPicker, setOpenPicker] = useState(false);

    const getNextAllowedDate = () => {
        if (!data) return null; 
        const today = moment();
        for (let i = 0; i < 7; i++) {
            const nextDate = today.clone().add(i, 'days');
            const dayOfWeek = nextDate.isoWeekday(); 
            const daysMapping: Record<number, keyof ILines> = {
                1: 'MONDAY',
                2: 'TUESDAY',
                3: 'WEDNESDAY',
                4: 'THURSDAY',
                5: 'FRIDAY',
                6: 'SATURDAY',
                7: 'SUNDAY',
            };
            const dayKey = daysMapping[dayOfWeek];
            if (data[dayKey]) {
                return nextDate.toDate();
            }
        }

        return null;
    };



    const handleDateChange = (newValue: Moment | null) => {
        setDeliveryDate(new Date(newValue?.toString()!));
        setOpenPicker(false);
    };

    const shouldDisableDate = (date: Moment) => {
        // Define today (start of the day) for consistency.
        const today = moment().startOf('day');
      
        // Disable any date before today.
        if (date.isBefore(today)) {
          return true;
        }
      
        // Disable dates after the next 7 days.
        // const lastAllowedDate = moment().add(7, 'days').endOf('day');
        // if (date.isAfter(lastAllowedDate)) {
        //   return true;
        // }
      
        // If no data available, disable the date.
        if (!data) {
          return true;
        }
      
        // Map the day of week to your data keys.
        const dayOfWeek = date.isoWeekday();
        const daysMapping: Record<number, keyof ILines> = {
          1: 'MONDAY',
          2: 'TUESDAY',
          3: 'WEDNESDAY',
          4: 'THURSDAY',
          5: 'FRIDAY',
          6: 'SATURDAY',
          7: 'SUNDAY',
        };
      
        const dayKey = daysMapping[dayOfWeek];
      
        // Disable the date if the corresponding day is not available in your data.
        return !data[dayKey];
      };
      

    useEffect(() => {
        if (data) {
            const nextDate = getNextAllowedDate();
            if (nextDate) {
                setDeliveryDate(nextDate);
            }
        }
    }, [data]);

    const minimumOrder = user?.minimumOrder || 0;
    const totalPackUnits = cart.reduce((total, item) => total + item.price, 0);
    const progressPercentage = minimumOrder ? Math.min((totalPackUnits / minimumOrder) * 100, 100) : 0;
    const totalPrice = cart.reduce((total, item) => total + item.price, 0);
    const minOrder = user?.minimumOrder || 0;
    const isNotEligible = totalPrice <= minOrder;

    
    const handleSendOrder = () => {
        if (!user?.permission?.permissionDetaileds[0]?.isOrderable) {
            onErrorNotify('אין הרשאה לבצע הזמנה');
            return;
        }
        sendOrder(user!, deliveryDate, choosedArea!).then((e) => {
            if (e?.status === 'success') {
                navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${e.data?.documentNumber}`);
                onSuccessNotify(e.message);
                setCart([])
            } else {
                onErrorNotify(e.message);
            }
        });
    };


    return (
        <Box sx={{ padding: '20px' }}>
            {loading ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', margin:'20px 0'}}>
                    <CustomCircularProgress/>
                </Box>
            :
                <>
                <Typography fontWeight={700} fontSize={'15px'} color='#154D72'>
                    בחרו תאריך למשלוח
                </Typography>
                <Typography fontWeight={400} fontSize={'15px'} color='#154D72'>
                    בחרו תאריך מתוך התאריכים הזמינים
                </Typography>
                <Box
                    onClick={() => setOpenPicker(true)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        justifyContent: 'space-between',
                        bgcolor: 'white',
                        borderRadius: '14px',
                        padding: '12px 12px 12px 10px',
                        border: '1px solid #80A8B6',
                        mt: '20px',
                    }}
                >
                    <Typography
                        color='#546D79'
                        fontSize={'13px'}
                        lineHeight={'19px'}
                        fontWeight={400}
                    >
                        {moment(deliveryDate).format('DD/MM/YYYY')}
                    </Typography>
                    <MyIcon />
                </Box>
                <Box sx={{ margin: '20px 0' }}>
                    {openPicker && (
                        <DatePicker
                            open={!!openPicker}
                            onClose={() => setOpenPicker(false)}
                            value={moment(deliveryDate)}
                            onChange={(e) => handleDateChange(e)}
                            shouldDisableDate={shouldDisableDate}
                            slotProps={{
                                actionBar: {
                                    actions: [],
                                },
                            }}
                        />
                    )}
                </Box>
                <Box sx={{ marginBottom: '20px' }}>
                    <TextField multiline rows={2} placeholder='הוסיפו הערה...' fullWidth />
                </Box>
                <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSendOrder}
                        sx={{
                            position: 'relative',
                            overflow: 'hidden',
                            height: '57px',
                            borderRadius: '14px',
                            ...(isNotEligible && {
                                bgcolor:'#80A8B6'
                            }),
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                height: '100%',
                                width: `${progressPercentage}%`,
                                backgroundColor: '#27C9D4',
                                transition: 'width 0.3s ease',
                                boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
                                zIndex: 0,
                            }}
                        />
                        <Typography
                            sx={{
                                position: 'relative',
                                zIndex: 1,
                                color: 'white',
                                fontWeight: 700,
                                fontSize: '15px',
                            }}
                        >
                            אישור הזמנה
                        </Typography>
                    </Button>
                    <Typography color='#546D79' fontWeight={400} fontSize={'13px'} sx={{mt:'15px'}}>
                    • אנא הוסיפו פריטים כדי להגיע למינימום ההזמנה
                    </Typography>
                    <Typography color='#546D79' fontWeight={400} fontSize={'13px'}>
                    • אנא בחרו תאריך למשלוח לאישור ההזמנה
                    </Typography>
                </>
            }
        </Box>
    );
};

export default Calendar;