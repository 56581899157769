import React, { FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ModalWrapper from '../Modals/ModalWrapper';
import { Backdrop, Box, Button, Checkbox, Chip, FormControl, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useDataArea from '../../hooks/useDataArea';
import useDataPermission from '../../hooks/useDataPermission';
import useDataCompanyUsers from '../../hooks/useDataCompanyUsers';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

interface CreateModalProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

interface IFormInput {
    fullName: string;
    phone: string;
    areas: string[];
    selectedPermission: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateModal: FC<CreateModalProps> = ({ openModal, setOpenModal }) => {
    const { data } = useDataArea();
    const { data: Tags } = useDataPermission();
    const { create } = useDataCompanyUsers();

    const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm<IFormInput>({
        defaultValues: {
            fullName: '',
            phone: '',
            areas: [],
            selectedPermission: 0,
        },
    });

    // State for a single selected permission tag
    const [selectedPermission, setSelectedPermission] = useState<number | null>(null);

    const handleChipClick = (item: IPermission) => {
        // If the clicked tag is already selected, unselect it; otherwise, select it.
        setSelectedPermission(selectedPermission === item.id ? null : item.id);
    };

    const onSubmit = (formData: IFormInput) => {
        console.log('formData',formData)
        // You can also include selectedPermission in your submit logic if needed
        if(selectedPermission){
            create(formData.fullName, formData.phone, formData.areas,selectedPermission);
            setOpenModal(false);
            reset()
        }
    };

    return (
        <ModalWrapper 
            active={openModal} 
            setActive={setOpenModal}
            component2={
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Button onClick={() => setOpenModal(false)} sx={{ height: '35px', textDecoration: 'underline' }}>
                        ביטול
                    </Button>
                    <Button 
                        onClick={handleSubmit(onSubmit)}
                        type='submit' 
                        variant='contained' 
                        sx={{ height: '35px' }}
                        endIcon={<CheckCircleOutlineRoundedIcon sx={{ height: '25px', width: '25px' }} />}
                    > 
                        שמירה
                    </Button>
                </Box>
            }
        >
            <form>
                <Box>
                    <Box>
                        <Grid container spacing={'5px'} direction="column">
                            {/* Full Name Field */}
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid size={2}>
                                    <Typography align="right" fontSize={'13px'} color="#154D72" fontWeight={700} lineHeight={'13px'}>
                                        שם מלא*
                                    </Typography>
                                </Grid>
                                <Grid size={10}>
                                    <Controller
                                        name="fullName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField fullWidth {...field} error={!!errors.fullName} helperText={errors.fullName ? 'Full Name is required' : ''} />
                                        )}
                                        rules={{ required: 'Full Name is required' }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Phone Field */}
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid size={2}>
                                    <Typography align="right" fontSize={'13px'} color="#154D72" fontWeight={700} lineHeight={'13px'}>
                                        טלפון*
                                    </Typography>
                                </Grid>
                                <Grid size={10}>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField fullWidth {...field} error={!!errors.phone} helperText={errors.phone ? 'Phone is required' : ''} />
                                        )}
                                        rules={{ required: 'Phone is required' }}
                                    />
                                </Grid>
                            </Grid>

                            {/* Area Assignment */}
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid size={2}>
                                    <Typography align="right" fontSize={'13px'} color="#154D72" fontWeight={700} lineHeight={'13px'}>
                                        שיוך לסניף*
                                    </Typography>
                                </Grid>
                                <Grid size={10}>
                                    <FormControl sx={{ width: '100%', maxWidth: '350px' }}>
                                        <Controller
                                            name="areas"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    fullWidth
                                                    multiple
                                                    value={field.value}
                                                    sx={{
                                                        border: '1px solid #80A8B6',
                                                        borderRadius: '14px'
                                                    }}
                                                    MenuProps={{
                                                        BackdropComponent: (props) => <Backdrop {...props} sx={{ background: 'none' }} />,
                                                        PaperProps: {
                                                            sx: {
                                                                borderBottomLeftRadius: '14px',
                                                                borderBottomRightRadius: '14px',
                                                            },
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        const { target: { value } } = e;
                                                        setValue(
                                                            'areas',
                                                            typeof value === 'string' ? value.split(',') : value
                                                        );
                                                    }}
                                                    renderValue={(selected) =>
                                                        selected
                                                            .map((code) => {
                                                                const area = data?.find((a: any) => a.code === code);
                                                                return area?.title || '';
                                                            })
                                                            .join(', ')
                                                    }
                                                >
                                                    {data?.map((area: any) => (
                                                        <MenuItem key={area.code} value={area.code}>
                                                            <Checkbox checked={field.value.includes(area.code)} />
                                                            <ListItemText primary={area.title} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Permissions */}
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid size={3}>
                                    <Typography align="right" fontSize={'13px'} color="#154D72" fontWeight={700} lineHeight={'13px'}>
                                        תפקיד והרשאות
                                    </Typography>
                                </Grid>
                                <Grid size={9}>
                                    {Tags?.map((item: IPermission) => (
                                        <Chip
                                            key={item.id}
                                            label={item.title}
                                            clickable
                                            onClick={() => handleChipClick(item)}
                                            variant={selectedPermission === item.id ? 'filled' : 'outlined'}
                                            sx={{
                                                border: `1px solid ${item.color}`,
                                                color: selectedPermission === item.id ? '#fff' : item.color,
                                                backgroundColor: selectedPermission === item.id ? item.color : 'transparent',
                                                margin: '2px'
                                            }}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography fontSize={'13px'} lineHeight={'19px'} fontWeight={400} color="#80A8B6" sx={{ mt: '10px' }}>
                        הרשאות מנהל סניף: הוספת עובדים למערכת, ביצוע הזמנות, צפייה בהסטורית הזמנות, אישור קבלת מוצר וסגירת קריאה, פתיחת קריאות שירות
                    </Typography>
                </Box>
            </form>
        </ModalWrapper>
    );
};

export default CreateModal;
