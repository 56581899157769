import { Box, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Divider, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import URLS from '../../enums/route';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../store/auth.store';
import ModalWrapper from '../Modals/ModalWrapper';
import { useArea } from '../../store/area.store';
import { themeColors } from '../../styles';
import {One, Two, Three, Four, Five, Six, Seven, Eight} from './IconsCont'
import { link } from 'fs';


const ListMenu = () => {
    const navigate = useNavigate()
    const {setChoosedArea} = useArea()
    const [toExit, setToExit] = useState(false)
    const [toDelete, setToDelete] = useState(false)
    const {user,logout} = useAuth()
    const isAllowAdmin = user?.isOwner || user?.permission?.id === 1 || user?.permission?.id === 2

    const handleFuncLogOut = () => {
        logout()
        navigate(URLS.AuthHome.LINK)
    }
    const data = [
        {
            title: 'ניהול העסק',
            lines: [
                {
                    icon: <One/>,
                    title: 'הרשאות, עובדים וסניפים',
                    link: URLS.BUSSNIES.LINK,
                    isOnlyAdmin: false,
                },
                {
                    icon: <Two/>,
                    title: 'ארכיון',
                    link: user?.isOwner ? URLS.DOCUEMNTS_MENU_CHOOSE_AREA.LINK : URLS.DOCUEMNTS_MENU.LINK,
                    isOnlyAdmin: false,
                },
                // {
                //     icon: <Three/>,
                //     title: 'הגדרות משתמש',
                //     link: URLS.SETTINGS.LINK
                // },
            ]
        },
        {
            title: 'עזרה',
            lines: [
                {
                    icon: <Four/>,
                    title: 'שאלות נפוצות',
                    link: URLS.FAQ.LINK,
                    isOnlyAdmin: false,
                },
                {
                    icon: <Five/>,
                    title: 'שירות לקוחות ויצירת קשר',
                    link: URLS.SUPPORT.LINK,
                    isOnlyAdmin: false,
                },
                {
                    icon: <Six/>,
                    title: 'צ׳אט',
                    link:'https://wa.me/9720733743750',
                    isOnlyAdmin: false,
                },
            ]
        },
        {
            title: 'מידע כללי',
            lines: [
                // {
                //     icon: <Seven/>,
                //     title: 'נגישות',
                //     link:'',
                //     isOnlyAdmin: false,
                // },
                {
                    icon: <Eight/>,
                    title: 'תקנון ותנאי שימוש',
                    link:URLS.POLICY_PAGE.LINK,
                    isOnlyAdmin: false,
                },
            ]
        }
    ]
    return (
        <>
        <Box sx={{ width: '100%', height: '100%', mt:'15px', pb:'20px' }}>
            {data?.map((item, index) => (
                <List
                    key={index}
                    sx={{ width: '100%', bgcolor: themeColors.baseDark }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader" sx={{ fontWeight: 700, fontSize: '20px', color:'#154D72', lineHeight:'28px', bgcolor: themeColors.baseDark, mt:'10px', mb:'5px' }}>
                            {item?.title}
                        </ListSubheader>
                    }
                >
                    {item?.lines?.map((item2, index2) => {
                        if(isAllowAdmin || !item2?.isOnlyAdmin) {
                            return (
                                <React.Fragment key={index2}>
                                    {item2?.link.startsWith('tel:') || item2?.link.startsWith('https://wa.me/') ? (
                                        <a
                                            href={item2?.link}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'inherit',
                                            }}
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                        >
                                            <ListItemButton>
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 35,
                                                    }}
                                                >
                                                    {item2?.icon}
                                                </ListItemIcon>
                                                <Typography
                                                    sx={{
                                                        color: '#154D72',
                                                        fontSize: '15px',
                                                        fontWeight: 700,
                                                        lineHeight: '21px',
                                                    }}
                                                >
                                                    {item2?.title}
                                                </Typography>
                                            </ListItemButton>
                                        </a>
                                    ) : (
                                        <ListItemButton onClick={() => navigate(item2?.link!)}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 35,
                                                }}
                                            >
                                                {item2?.icon}
                                            </ListItemIcon>
                                            <Typography
                                                sx={{
                                                    color: '#154D72',
                                                    fontSize: '15px',
                                                    fontWeight: 700,
                                                    lineHeight: '21px',
                                                }}
                                            >
                                                {item2?.title}
                                            </Typography>
                                        </ListItemButton>
                                    )}
                                    {index2 < item?.lines?.length - 1 && <Divider />}
                                </React.Fragment>
                            )
                        }
                        
                    })}


      
                </List>
            ))}
            <Divider />
            <ListItemButton onClick={() => setToExit(!toExit)}> 
                <ListItemIcon sx={{minWidth: 35 }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Component 2">
                        <path
                            id="Icon"
                            d="M16 17.0003L21 12.0003M21 12.0003L16 7.00031M21 12.0003H9M12 17.0003C12 17.2959 12 17.4437 11.989 17.5717C11.8748 18.9023 10.8949 19.9972 9.58503 20.2576C9.45903 20.2826 9.31202 20.299 9.01835 20.3316L7.99694 20.4451C6.46248 20.6156 5.69521 20.7008 5.08566 20.5058C4.27293 20.2457 3.60942 19.6518 3.26118 18.8728C3 18.2885 3 17.5165 3 15.9726V8.028C3 6.48407 3 5.71211 3.26118 5.12783C3.60942 4.34879 4.27293 3.75491 5.08566 3.49483C5.69521 3.29978 6.46246 3.38502 7.99694 3.55552L9.01835 3.66901C9.31212 3.70165 9.45901 3.71797 9.58503 3.74303C10.8949 4.00346 11.8748 5.09835 11.989 6.42891C12 6.55694 12 6.70473 12 7.00031"
                            stroke="#FF32BB"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </g>
                    </svg>
                </ListItemIcon>
                <Typography 
                    sx={{
                        color: '#154D72', 
                        fontSize: '15px', 
                        fontWeight: 700,
                        lineHeight: '21px'
                    }}
                >
                    {'צא מהמערכת'}
                </Typography>
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => setToDelete(!toDelete)}> 
                <ListItemIcon sx={{minWidth: 35}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Component 2">
                        <path
                            id="Icon"
                            d="M16.5 16L21.5 21M21.5 16L16.5 21M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
                            stroke="#FF32BB"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </g>
                    </svg>
                </ListItemIcon>
                <Typography 
                    sx={{
                        color: '#154D72', 
                        fontSize: '15px', 
                        fontWeight: 700,
                        lineHeight: '21px'
                    }}
                >
                    {'מחק משתמש'}
                </Typography>
            </ListItemButton>
            <Divider />
        </Box>
        <ModalWrapper 
            active={toExit} 
            setActive={setToExit}
            component={
                <Typography color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                    כבר עוזבים?
                </Typography>
            }
        >
            <Box>
                <Typography 
                    textAlign={'center'}
                    color='#436F7C'
                    fontSize={'13px'}
                    fontWeight={700}
                    lineHeight={'19.5px'}
                >
                שימו לב! 
                </Typography>
                <Typography
                    textAlign={'center'}
                    fontSize={'13px'}
                    lineHeight={'19.5px'}
                    fontWeight={400}
                    color='#436F7C'
                >
                הינכם עומדים לצאת מהמערכת.
                </Typography>
                <Button 
                    onClick={() => handleFuncLogOut()}
                    fullWidth
                    sx={{
                        mt:'20px',
                        alignItems:'center',
                    }}
                    variant='outlined' 
                    color='info' 
                    startIcon={ 
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Component 2">
                            <path
                                id="Icon"
                                d="M16 17.0003L21 12.0003M21 12.0003L16 7.00031M21 12.0003H9M12 17.0003C12 17.2959 12 17.4437 11.989 17.5717C11.8748 18.9023 10.8949 19.9972 9.58503 20.2576C9.45903 20.2826 9.31202 20.299 9.01835 20.3316L7.99694 20.4451C6.46248 20.6156 5.69521 20.7008 5.08566 20.5058C4.27293 20.2457 3.60942 19.6518 3.26118 18.8728C3 18.2885 3 17.5165 3 15.9726V8.028C3 6.48407 3 5.71211 3.26118 5.12783C3.60942 4.34879 4.27293 3.75491 5.08566 3.49483C5.69521 3.29978 6.46246 3.38502 7.99694 3.55552L9.01835 3.66901C9.31212 3.70165 9.45901 3.71797 9.58503 3.74303C10.8949 4.00346 11.8748 5.09835 11.989 6.42891C12 6.55694 12 6.70473 12 7.00031"
                                stroke="#FF32BB"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            </g>
                        </svg>
                    }
                >
                    יציאה מקסיופאיה 
                </Button>
                <Button 
                    onClick={() => setToExit(!toExit)}
                    fullWidth
                    sx={{
                        mt:'20px'
                    }}
                >
                חזרה לפרופיל
                </Button>
            </Box>
        </ModalWrapper>
        <ModalWrapper 
            active={toDelete} 
            setActive={setToDelete}
            component={
                <Typography color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                    מחיקת משתמש
                </Typography>
            }
        >
            <Box>
                <Typography 
                    textAlign={'center'}
                    color='#436F7C'
                    fontSize={'13px'}
                    fontWeight={700}
                    lineHeight={'19.5px'}
                >
                שימו לב! 
                </Typography>
                <Typography
                    textAlign={'center'}
                    fontSize={'13px'}
                    lineHeight={'19.5px'}
                    fontWeight={400}
                    color='#436F7C'
                >
                    
                המידע המקושר למשתמש שלכם ימחק<br/>
                לצמיתות ללא אפשרות שחזור, שנמשיך?
                </Typography>
                <Button 
                    fullWidth
                    variant='outlined' 
                    color='info' 
                    sx={{
                        mt:'20px'
                    }}
                    startIcon={ 
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Component 2">
                            <path
                                id="Icon"
                                d="M16.5 16L21.5 21M21.5 16L16.5 21M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
                                stroke="#FF32BB"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            </g>
                        </svg>
                    }
                >
                מחיקת משתמש 
                </Button>
                <Button 
                    sx={{
                        mt:'20px'
                    }}
                    onClick={() => setToDelete(!toDelete)}
                    fullWidth
                >
                חזרה לפרופיל
                </Button>
            </Box>
        </ModalWrapper>
        </>
    );
};

export default ListMenu;
