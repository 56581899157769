import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'
import { CatalogService } from '../services/catalog.service'
import { DocumentService } from '../services/document.service'

interface useCartState {
  loading: boolean
  cart: ICart[]
  setCart: (cart: ICart[]) => void
  clearCart: () => void
  getCartItem: (product: { sku: string }) => ICart | null
  addToCart: (product: IProduct) => void
  increaseCart: (sku: string) => void
  decreaseCart: (sku: string) => void
  deleteFromCart: (sku: string) => void
  changeQuantity: (sku: string, quantity: number, func: (product: IProduct) => void) => void
  deliveryDate: Date
  setDeliveryDate: (deliveryDate: Date) => void
  sendOrder: (
    user: IUser,
    deliveryDate: Date,
    area: IArea
  ) => Promise<ISendOrderResponse>
}

export const useCart = create(
  persist(
    (set, get) => ({
      loading: false,
      cart: [],
      setCart: (cart: ICart[]) => set({cart}),
      clearCart: () => {set({cart:[]})},
      getCartItem: (product): ICart | null => {
        const cart = get().cart
        const itemFind = cart.find((item) => item.sku === product.sku)
        if (itemFind) {
          return itemFind
        } else {
          return null
        }
      },
      addToCart: (product: IProduct) => {
        const { cart } = get();
        const quantity = product.minimumQuantity;
        const cartProduct: ICart = {
          sku: product.sku,
          quantity,
          price: product.price * quantity,
          product,
        };
        set({ cart: [...cart, cartProduct] });
      },
      increaseCart: (sku: string) => {
        if (!get().loading) {
          const cart = get().cart;
          const itemIndex = cart.findIndex((item) => item.sku === sku);
          if (itemIndex !== -1) {
            cart[itemIndex].quantity += 1;
            cart[itemIndex].price = cart[itemIndex].product.price * cart[itemIndex].quantity;
          } else {
            console.error('Item not found in cart');
          }
          set({ cart });
        }
      },
      decreaseCart: (sku: string) => {
        if (!get().loading) {
          const cart = get().cart;
          const itemIndex = cart.findIndex((item) => item.sku === sku);
          if (itemIndex !== -1) {
            const minQuantity = cart[itemIndex].product.minimumQuantity;
            const newQuantity = cart[itemIndex].quantity - 1;
            if (newQuantity < minQuantity) {
              // Remove the product if quantity goes below the minimum
              const filteredCart = cart.filter((item) => item.sku !== sku);
              set({ cart: filteredCart });
            } else {
              cart[itemIndex].quantity = newQuantity;
              cart[itemIndex].price = cart[itemIndex].product.price * newQuantity;
              set({ cart });
            }
          } else {
            console.error('Item not found in cart');
          }
        }
      },
      deleteFromCart: (sku) => {
        const cart = get().cart;
        const filtered = cart.filter((item) => item.sku !== sku);
        set({ cart: filtered });
      },
      changeQuantity: (sku: string, quantity: number, func: (product: IProduct) => void) => {
        const cart = get().cart;
        const itemIndex = cart.findIndex((item) => item.sku === sku);
        if (itemIndex !== -1) {
          const minQuantity = cart[itemIndex].product.minimumQuantity;
          const newQuantity = Number(quantity);
          if (newQuantity > 10) {
            if (newQuantity >= minQuantity) {
              cart[itemIndex].quantity = newQuantity;
              cart[itemIndex].price = cart[itemIndex].product.price * newQuantity;
            } else {
              console.error(
                `Error: Quantity (${newQuantity}) must be at least ${minQuantity} for this product when ordering more than 10. Removing product from cart.`
              );
              // Remove the product from the cart
              func(cart[itemIndex].product);
              const filteredCart = cart.filter((item) => item.sku !== sku);
              set({ cart: filteredCart });
              return;
            }
          } else {
            cart[itemIndex].quantity = newQuantity;
            cart[itemIndex].price = cart[itemIndex].product.price * newQuantity;
          }
        } else {
          console.error('Item not found in cart');
        }
        set({ cart });
      },
      deliveryDate: new Date,
      setDeliveryDate:  (deliveryDate) => set({deliveryDate}),
      sendOrder: async (user: IUser, deliveryDate: Date, area: IArea) => {
        try {
          set({ loading: true })
          const response = await DocumentService.sendOrder(user,deliveryDate,area,get().cart)
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בשידור' + e , data: null}
        } finally {
          set({ loading: false })
        }
      }
    }),
    {
      name: 'cart-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<useCartState, useCartState>
  )
)
