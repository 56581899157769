import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import useDataMachine from '../../hooks/useDataMachine';
import Grid from '@mui/material/Grid2';
import { useService } from '../../store/service.store';

const Tags = () => {
    const { data } = useDataMachine();
    const { selectedTags, setSelectedTags, openText, setOpenText} = useService(); 

    return (
        <Box sx={{paddingTop:'20px'}}>
            <Typography color='#154D72' fontSize={'20px 10px'} fontWeight={700} lineHeight={'28px'}>
                ספרו לנו מה התקלה
            </Typography>
            <Grid container spacing={'5px'} justifyContent="center">
                {data?.tagGroups?.map((item) => {
                    const isSelected = selectedTags.some(selected => selected.id === item.id);

                    return (
                        <Grid key={item.id}>
                            <Stack sx={{cursor:'pointer'}} direction="row" spacing={1} onClick={() => setSelectedTags(item)}>
                                <Chip
                                    label={item.tag.title}
                                    variant={isSelected ? 'filled' : 'outlined'}
                                    color='primary'
                                    sx={{
                                        fontSize:'12px',
                                        fontWeight:700,
                                        lineHeight:'19px',
                                        '&.MuiChip-filled': {
                                            backgroundColor: 'primary.main', // Set background color for filled variant
                                            color: 'white', // Set text color to white for filled variant
                                        },
                                    }}
                                />
                            </Stack>
                        </Grid>
                    );
                })}
                {!openText &&
                    <Grid>
                        <Stack sx={{cursor:'pointer'}} direction="row" spacing={1} onClick={() => setOpenText(!openText)}>
                            <Chip
                                label={'הוסף הערה ידנית'}
                            />
                        </Stack>
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default Tags;
