import { Box, Button, Chip, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useCatalog } from '../../store/catalog.store';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCart } from '../../store/cart.store';
import ModalWrapper from '../Modals/ModalWrapper';
import { themeColors } from '../../styles';
import ClearCart from '../Common/ClearCart';
import useDataCatalog from '../../hooks/useDataCatalog';

const Options = () => {
    const {openFilter, setOpenFilter, openFavorite, setOpenFavorite, selectedCategories, setSelectedCategoies, clearFilter} = useCatalog()
    
    const {cart} = useCart()
    const [openClearModal, setOpenClearModal] = useState(false)
    const {data} = useDataCatalog()
    return (
        <>
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mt:'20px', padding:'0 10px'}}>
            <Typography fontWeight={700} fontSize={'20ox'} lineHeight={'28px'} color={themeColors.title}>
            קטלוג המוצרים
            </Typography>
            <Box>
                <IconButton onClick={() => setOpenFavorite(!openFavorite)}>
                    {openFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon sx={{color:themeColors.title}}/>}
                </IconButton>
  
                {!openFilter &&
                    <IconButton onClick={() => setOpenFilter(true)}>
                        <img src={`${process.env.REACT_APP_MEDIA}/filter.svg`} />
                    </IconButton>
                }
            </Box>
        </Box>
        {cart.length > 0 &&
        <Box sx={{display:'flex', gap:'5px', mb:'20px', padding:'0 10px'}} onClick={() => setOpenClearModal(!openClearModal)}>
            <Typography  color='info' sx={{textDecoration:'underline', cursor:'pointer'}}>
            איפוס הזמנה
            </Typography>
            <img src={`${process.env.REACT_APP_MEDIA}/clearCart.svg`} />
        </Box>
        }
        <ModalWrapper 
            active={openClearModal} 
            setActive={setOpenClearModal}
            component={
                <Typography color='info' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                    איפוס הזמנה
                </Typography>
            }
        >
            <ClearCart closeFunc={() => setOpenClearModal(false)}/>
        </ModalWrapper>
        {openFilter &&
            <Box sx={{mt:'20px'}}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                        סינון
                    </Typography>
                    <Box sx={{display:'flex', gap:'5px', alignItems:'center', cursor:'pointer'}} onClick={() => clearFilter()}>
                        <Typography color='info' sx={{textDecoration:'underline'}}>
                        בטל סינון
                        </Typography>
                        <img src={`${process.env.REACT_APP_MEDIA}/clearCart.svg`} />
                    </Box>
                </Box>
            </Box>
        }
        {openFilter &&
            <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{mt:'10px', gap:'10px'}}>
                {data?.map((item) => (
                    <Grid key={item.category}>
                            <Chip
                                color='primary'
                                label={item.category}
                                sx={{
                                    fontWeight: 700,
                                    fontSize:'12px',
                                    lineHeight:'19px',
                                    '&.MuiChip-filled': {
                                        backgroundColor: 'primary.main', 
                                        color: 'white', 
                                    },
                                }}
                                onClick={() => {
                                    if (selectedCategories.includes(item.category)) {
                                        setSelectedCategoies(selectedCategories.filter(category => category !== item.category));
                                    } else {
                                        setSelectedCategoies([...selectedCategories, item.category]); // This should be an array
                                    }
                                }}
                                variant={selectedCategories.includes(item.category) ? 'filled' : 'outlined'} // Update the variant check
                            />
                    </Grid>
                ))}
            </Grid>
        }
        </>

    );
};

export default Options;