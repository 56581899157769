import React, { useEffect, useState } from 'react';
import SecondPageLayout from '../components/Layout/SecondPageLayout';
import { Box, CircularProgress, Typography } from '@mui/material';
import { usePolicy } from '../store/policy.store';

  
const PrivacyPolicyPage = () => {
    const {} = usePolicy();
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the privacy policy content
        const fetchPrivacyPolicy = async () => {
            try {
                const response = await fetch('https://shanishenhav.online/privacy.php');
                if (!response.ok) {
                    throw new Error('Failed to fetch privacy policy');
                }
                const text = await response.text();
                setPrivacyPolicy(text);
            } catch (error) {
                console.error('Error fetching privacy policy:', error);
                setPrivacyPolicy('<p>Failed to load privacy policy.</p>'); // Show an error message
            } finally {
                setLoading(false);
            }
        };

        fetchPrivacyPolicy();
    }, []);

    return (
        <SecondPageLayout withFooter={true}>
            <Box sx={{display:'flex', gap:'10px', alignItems:'center', mb:'10px'}}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="Component 2">
                    <path
                        id="Icon"
                        d="M21 12L9 12M21 6L9 6M21 18L9 18M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12ZM5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6ZM5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z"
                        stroke="#2F4B6C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    </g>
                </svg>
                <Typography color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                תקנון ותנאי שימוש
                </Typography>
            </Box>
            <Box>
                {loading ? (
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box
                    dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                    sx={{
                      textAlign: 'justify',
                      maxWidth: '100%',
                      color: '#436F7C !important',
                      overflowX: 'auto', // adds horizontal scroll if needed
                      '& p': {
                        color: '#436F7C',
                        fontSize: '13px',
                        lineHeight: '19px',
                        wordBreak: 'break-word', // breaks long words
                      },
                      '& span': {
                        color: '#436F7C',
                        fontSize: '13px',
                        lineHeight: '19px',
                        wordBreak: 'break-word',
                      },
                      '& img': {
                        maxWidth: '100%', // ensures images don't exceed container width
                        height: 'auto',
                      },
                    }}
                    />
                )}
            </Box>
            <Box sx={{display:'flex', justifyContent:'center'}}>
                <img src={`${process.env.REACT_APP_MEDIA}/policyIlustrate.png`} />
            </Box>
        </SecondPageLayout>
    );
};

export default PrivacyPolicyPage;
