import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { AreaService } from '../services/area.service'
import { useArea } from '../store/area.store'
import { CatalogService } from '../services/catalog.service'
import { PermissionService } from '../services/permissions.service'
import { useNotify } from '../provider/NotifyProvider'

const fetchData = async (
  userExId: string,
  userId: number
): Promise<IUser[]> => {
  return await PermissionService.getCompanyUsers(userExId,userId)
}

const useDataCompanyUsers = () => {
  const { user } = useAuth()
  const {onErrorNotify, onSuccessNotify} = useNotify()
  const { data, error, isLoading, mutate } = useSWR<IUser[]>(
    `/client/employer/${user?.extId}/${user?.id}`,
    () => fetchData(user?.extId!,user?.id!)
  )

  const create = (fullName: string, phone: string, areas: string[], selectedPermission?: number) => {
    try {
        PermissionService.createUser(fullName,phone,areas,user?.extId!,selectedPermission).then((e) => {
            if(e.status === 'success'){
                onSuccessNotify(e.message)
                mutate()
            } else {
                onErrorNotify(e.message)
            }
        })
    } catch(e) {
        onErrorNotify('[ERROR]')
    } 
  }

  const createFreeField = (keyword: string, valueword: string, userId:number) => {
      try {
        PermissionService.createFreeField(keyword,valueword,userId).then((e) => {
            if(e.status === 'success'){
                onSuccessNotify(e.message)
                mutate()
            } else {
                onErrorNotify(e.message)
            }
        })
    } catch(e) {
        onErrorNotify('[ERROR]')
    } 
  }

  const unpublishUser = (userId: number) => {
    try {
        PermissionService.unpublishUser(userId).then((e) => {
            if(e.status === 'success'){
                onSuccessNotify(e.message)
                mutate()
            } else {
                onErrorNotify(e.message)
            }
        })
    } catch(e) {
        onErrorNotify('[ERROR]')
    } 
  }

  const update = (fullName: string, areas: string[],userId:string|number, permission: IPermission | null) => {
    try {
        PermissionService.updateUser(fullName,areas,userId,permission).then((e) => {
            if(e.status === 'success'){
                onSuccessNotify(e.message)
                mutate()
            } else {
                onErrorNotify(e.message)
            }
        })
    } catch(e) {
        onErrorNotify('[ERROR]')
    } 
  }

  return {
    data,
    isLoading: isLoading,
    isError: error,
    createFreeField,
    mutate,
    create,
    update,
    unpublishUser,
  }
}

export default useDataCompanyUsers

