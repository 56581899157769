import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import FooterNavigation from './FooterNavigation';
import PullToRefresh from 'react-simple-pull-to-refresh';
import theme, { themeColors } from '../../styles';
import { useLocation, useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import useDataMachines from '../../hooks/useDataMahines';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import useDataLastServices from '../../hooks/useDataLastServices';
import useDataCatalog from '../../hooks/useDataCatalog';
import useDataNotifications from '../../hooks/useDataNotifications';
import CustomCircularProgress from '../Common/Preloader';
import { useCart } from '../../store/cart.store';
import { useAuth } from '../../store/auth.store';
import ModalWrapper from '../Modals/ModalWrapper';
import { handleColor } from '../../enums/serviceStatus';

interface LayoutProps {
    children: React.ReactNode;
    widhoutPadding?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, widhoutPadding = true }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [handler, setHandler] = useState(false)
    const [handler2, setHandler2] = useState(false)
    // Retain the ref if needed by PullToRefresh but we'll use window scroll events
    const mainRef = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { data } = useDataMachines();
    const { cart } = useCart();
    const { user } = useAuth()
    const { mutate: lastOrdersMutate, isLoading: isLoadingOrders } = useDataLastOrders(); 
    const { mutate: lastServicesMutate, isLoading: isLoadingServices } = useDataLastServices();
    const { mutate: catalogMutate, isLoading: isLoadingCatalog } = useDataCatalog();
    const { mutate: machineMutate, isLoading: machineLoading } = useDataMachines();
    const { mutate: notificationMutate, isLoading: notificationLoading } = useDataNotifications();

    // Use window scroll event instead of main container scroll
    useEffect(() => {
        const handleWindowScroll = () => {
            setScrolled(window.pageYOffset > 0);
        };

        window.addEventListener('scroll', handleWindowScroll);
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []);

    const waitForLoadingToFinish = (loadingState: () => boolean) => {
        return new Promise<void>((resolve) => {
            const checkLoading = () => {
                if (!loadingState()) {
                    resolve();
                } else {
                    setTimeout(checkLoading, 50); 
                }
            };
            checkLoading();
        });
    };
    
    const waitForLoadingToStart = (loadingState: () => boolean, timeout = 2000) => {
        return new Promise<void>((resolve, reject) => {
            const startTime = Date.now();
            const checkLoading = () => {
                if (loadingState()) {
                    resolve();
                } else if (Date.now() - startTime > timeout) {
                    reject(new Error('Timeout waiting for loading to start'));
                } else {
                    setTimeout(checkLoading, 50);
                }
            };
            checkLoading();
        });
    };
    
    const handleRefresh = async () => {
        setIsRefreshing(true);
        try {
            if (pathname === URLS.HOME.LINK) {
                await lastOrdersMutate(undefined);
                await waitForLoadingToStart(() => isLoadingOrders).catch((error) => {
                    console.error('Timeout waiting for isLoadingOrders to start:', error.message);
                });
                await waitForLoadingToFinish(() => isLoadingOrders);
                await lastServicesMutate(undefined);
                await waitForLoadingToStart(() => isLoadingServices).catch((error) => {
                    console.error('Timeout waiting for isLoadingServices to start:', error.message);
                });
                await waitForLoadingToFinish(() => isLoadingServices);
            }
    
            if (pathname === URLS.CATALOG.LINK) {
                await catalogMutate(undefined);
                await waitForLoadingToStart(() => isLoadingCatalog).catch((error) => {
                    console.error('Timeout waiting for isLoadingCatalog to start:', error.message);
                });
                await waitForLoadingToFinish(() => isLoadingCatalog);
            }
    
            if (pathname === URLS.SERVICE.LINK) {
                await machineMutate(undefined);
                await waitForLoadingToStart(() => machineLoading).catch((error) => {
                    console.error('Timeout waiting for machineLoading to start:', error.message);
                });
                await waitForLoadingToFinish(() => machineLoading);
            }
    
            if (pathname === URLS.NOTIFICATIONS.LINK) {
                await notificationMutate(undefined);
                await waitForLoadingToStart(() => notificationLoading).catch((error) => {
                    console.error('Timeout waiting for notificationLoading to start:', error.message);
                });
                await waitForLoadingToFinish(() => notificationLoading);
            }
    
            console.log('Refresh process completed');
        } catch (error) {
            console.error('Error during refresh:', error);
        } finally {
            setIsRefreshing(false);
        }
    };



    const minimumOrder = user?.minimumOrder || 0;
    const totalPackUnits = cart.reduce((total, item) => total + item.price, 0);
    const progressPercentage = minimumOrder ? Math.min((totalPackUnits / minimumOrder) * 100, 100) : 0;
    const totalPrice = cart.reduce((total, item) => total + item.price, 0);
    const minOrder = user?.minimumOrder || 0;
    const isNotEligible = totalPrice <= minOrder;


    const handleClick = () => {
        if(isNotEligible){
            setHandler(true)
        } else {
            navigate(URLS.CART.LINK)
        }
    }

    const handleSecondCLick = () => {
        if(isNotEligible){
            setHandler(false)
            setHandler2(true)
        } else {
            setHandler(false);
            navigate(URLS.CATALOG.LINK)
        }

    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box
                component="header"
                sx={{
                    position: 'fixed',
                    top: 0,
                    paddingTop: '50px',
                    boxShadow: scrolled ? '0px 7px 7px 0px #0000001A' : 'none',
                    left: 0,
                    right: 0,
                    zIndex: 1100,
                    bgcolor: themeColors.baseDark,
                }}
            >
                <Header />
                {isRefreshing && (
                    <>
                        {pathname === URLS.HOME.LINK && (
                            <Box
                                sx={{
                                    boxShadow: '0px 7px 7px 0px rgba(0, 0, 0, 0.10)', 
                                    height: '8px',
                                }}
                            />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: '10px',
                            }}
                        >
                            <CustomCircularProgress />
                        </Box>
                    </>
                )}
            </Box>
            <PullToRefresh onRefresh={handleRefresh} refreshingContent={<></>}>
                <Box
                    component="main"
                    ref={mainRef}
                    sx={{
                        flexGrow: 1,
                        marginTop: '100px',
                        marginBottom: '104px',
                        overflowY: 'auto',
                        position: 'relative',
                        padding: widhoutPadding ? '0 10px' : '0px',
                        paddingBottom: '40px',
                    }}
                >
                    {children}
                </Box>
            </PullToRefresh>
            <Box
                component="footer"
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1100,
                }}
            >
                {pathname === URLS.SERVICE.LINK && data?.length === 0 && (
                    <Box>
                        <Typography color="#79A3B4" fontSize={'13px'} fontWeight={700} textAlign={'center'}>
                            אופס!
                        </Typography>
                        <Typography sx={{ mb: '16px' }} color="#79A3B4" fontSize={'13px'} fontWeight={400} textAlign={'center'}>
                            נראה שאין פה כלום כרגע.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={`${process.env.REACT_APP_MEDIA}/noMachines.svg`} style={{ alignItems: 'center' }} />
                        </Box>
                    </Box>
                )}
                {pathname === URLS.CATALOG.LINK && cart?.length > 0 && (
                    <>
                        <Box
                            sx={{
                                bgcolor: "#F1F3F6",
                                height: "90px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px -4px 7px 0px #0000001A",
                            }}
                        >
                        <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            handleClick()
                        }}
                        sx={{
                            position: 'relative',
                            overflow: 'hidden',
                            width: "90%",
                            height: '57px',
                            borderRadius: '14px',
                            ...(isNotEligible && {
                                bgcolor:'#80A8B6'
                            }),
                        }}
                        >
                        <Box
                            sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            height: '100%',
                            width: `${progressPercentage}%`,
                            backgroundColor: '#27C9D4',
                            transition: 'width 0.3s ease',
                            boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
                            zIndex: 0,
                            }}
                        />
                        <Typography
                            sx={{
                            position: 'relative',
                            zIndex: 1,
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '15px',
                            }}
                        >
                            לאישור ההזמנה
                        </Typography>
                        <Box
                            sx={{
                            position: "absolute",
                            right: 15,
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: "flex",
                            alignItems: "center",
                            }}
                        >
                        </Box>
                        </Button>
                        </Box>
                        <Box sx={{ position: 'absolute', bgcolor: '#F1F3F6', height: '70px', width: '100%', bottom: '50px', zIndex: -1 }} />
                    </>
                )}
                <FooterNavigation />
            </Box>
            <ModalWrapper
                    active={handler} 
                    setActive={setHandler}
                    component={
                        <Typography color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                            דמי משלוח
                        </Typography>
                    }
                >
                <Box>
                    <Typography color='#436F7C' fontSize={'15px'} fontWeight={700} lineHeight={'22.5px'}>
                    שימו לב!
                    </Typography>
                    <Typography color='#546D79'>
                    ערך ההזמנה אינו עובר את המינימום הנדרש. יש להגדיל את ההזמנה בהתאם למינימום שנקבע או להוסיף דמי משלוח.
                    </Typography>
                    <Button 
                        onClick={() => handleSecondCLick()}
                        variant='outlined' 
                        fullWidth
                        sx={{
                            mt:'30px',
                        }}
                    >
                        הוסף דמי משלוח
                    </Button>
                    <Button
                        onClick={() => {setHandler(false);navigate(URLS.CATALOG.LINK)}}
                        color='info'
                        sx={{
                            mt:'20px'
                        }}
                        fullWidth
                    >
                    חזרה להזמנה
                    </Button>
                </Box>
            </ModalWrapper>
            <ModalWrapper
                    active={handler2} 
                    setActive={setHandler2}
                    component={
                        <Typography color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                            דמי משלוח
                        </Typography>
                    }
                >
                <Box>
                    <Typography color='#436F7C' fontSize={'15px'} fontWeight={700} lineHeight={'22.5px'}>
                    שימו לב!
                    </Typography>
                    <Typography color='#546D79'>
                    בתשלום של דמי משלוח, המשלוח לא יחושב במסגרת המשלוחים המגיעים לך לפי הסכם.
                    </Typography>
                    <Button 
                        onClick={() => {setHandler2(false);navigate(URLS.CART.LINK)}}
                        variant='outlined' 
                        fullWidth
                        sx={{
                            mt:'30px',
                        }}
                    >
                        אשר
                    </Button>
                    <Button
                        onClick={() => {setHandler2(false);navigate(URLS.CATALOG.LINK)}}
                        color='info'
                        sx={{
                            mt:'20px'
                        }}
                        fullWidth
                    >
                    חזרה להזמנה
                    </Button>
                </Box>
            </ModalWrapper>
        </Box>
    );
};

export default Layout;
