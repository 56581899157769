import { Backdrop, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useArea } from '../store/area.store';
import { useAuth } from '../store/auth.store';
import useDataArea from '../hooks/useDataArea';

const AreaSelector = () => {
  const { choosedArea, setChoosedArea } = useArea();
  const { user } = useAuth();
  const { data } = useDataArea();

  const isOwner = user!?.isOwner;

  const allowedAreaCodes = user!?.areaPermissions?.map(permission => permission.area.code) || [];

  const filteredData = isOwner ? data : data?.filter(item => allowedAreaCodes.includes(item.code));

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const selectedArea = filteredData?.find(item => item.code === selectedValue) || null;
    setChoosedArea(selectedArea);
  };

  return (
    <Select
      value={choosedArea?.code || ''}
      onChange={handleChange}
      sx={{
        width: '220px',
        '& .MuiSelect-select': { padding: '0px' },
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        color: '#80A8B6'
      }}
      MenuProps={{
        BackdropComponent: (props) => <Backdrop {...props} sx={{ background: 'none' }} />,
        PaperProps: {
          sx: {
            borderBottomLeftRadius: '14px',
            borderBottomRightRadius: '14px',
          },
        },
      }}
    >
      {filteredData?.map((item, index) => (
        <MenuItem value={item.code} key={index}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AreaSelector;
