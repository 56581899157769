import React from 'react';
import useDataMachineDocument from '../../hooks/useDataMachineDocument';
import { Box, Button, Chip, Divider, ImageList, ImageListItem, Paper, Rating, Skeleton, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import useDataQuestion from '../../hooks/useDataQuestion';
import { QuestionEnum } from '../../types/questionEnum';
import Grid from '@mui/material/Grid2';
import useIllustration from '../../hooks/useIllustration';
import QuestionDoc from './QuestionDoc';


const FilesList = () => {
    const {data, isLoading} = useDataMachineDocument()


    const handleDownload = (base64: string) => {
        const link = document.createElement('a');
        link.href = `${base64}`;
        link.download = 'document.pdf'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Box sx={{padding:'10px'}}>
            {!isLoading && !data?.questionDoc &&
                <QuestionDoc/>
            }
            <Divider sx={{width:'100%'}}/>

            {isLoading ?
            <Skeleton variant="text" sx={{width:'100%'}} height={30} />
            :
            <Typography sx={{mt:'10px'}} textAlign={'center'} color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                {data?.machine}
            </Typography>
            }

            {isLoading ?
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Skeleton variant="text" sx={{width:'60%', textAlign:'center'}} height={20}  />
            </Box>
            :
            <Typography textAlign={'center'} color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
            מס׳ מכשיר {data?.serialNumber} · סניף {data?.areaDescription} · {moment(data?.createdAt).format('DD/MM/YYYY')}
            </Typography>
            }

            <Typography fontWeight={700} fontSize={20} color='#154D72' lineHeight={'28px'}>
            פרטי התקלה
            </Typography>
            <Grid container spacing={1} sx={{margin:'10px 0'}}>
                {data?.tags?.map((item,index) => 
                <Grid key={index}>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            label={item.title}
                            variant={'filled'} 
                            color='primary'
                            sx={{
                                fontSize:'12px',
                                fontWeight:700,
                                lineHeight:'19px',
                                '&.MuiChip-filled': {
                                    backgroundColor: 'primary.main',
                                    color: 'white', 
                                },
                            }}
                        />
                    </Stack>
                </Grid>
                )}
            </Grid>
            {isLoading ?
                <Box>
                    <Skeleton variant="text" sx={{width:'60%', textAlign:'center'}} height={20}  width={200}/>
                    <Skeleton variant="text" sx={{width:'60%', textAlign:'center'}} height={20}  width={180}/>
                    <Skeleton variant="text" sx={{width:'60%', textAlign:'center'}} height={20}  width={150}/>
                    <Skeleton variant="text" sx={{width:'60%', textAlign:'center'}} height={20}  width={250}/>
                    <Skeleton variant="text" sx={{width:'60%', textAlign:'center'}} height={20}  width={50}/>
                </Box>
            :
            <Typography 
                color='#436F7C' 
                fontSize={'13px'} 
                fontWeight={400} 
                variant="body1" 
                component="div" 
                dangerouslySetInnerHTML={{ __html: data?.description! }} 
                sx={{
                    '& span': {
                        color: "#436F7C !important",
                        lineHeight: '19.5px !important',
                        fontSize: '13px !important',
                        bgcolor: '#F5F7FA !important', 
                    },
                    '& div' : {
                        color: "#436F7C !important",
                        lineHeight: '19.5px !important',
                        fontSize: '13px !important',
                        bgcolor: '#F5F7FA !important', 
                    },
                    '& p': {
                        color: "#436F7C !important",
                        lineHeight: '19.5px !important',
                        fontSize: '13px !important',
                        bgcolor: '#F5F7FA !important', 
                    },
                    '& font': {
                        color: "#436F7C !important",
                        lineHeight: '19.5px !important',
                        fontSize: '13px !important',
                        bgcolor: '#F5F7FA !important', 
                    }
                }}
            />
            
            }
            {data && data?.files?.length > 0 ?
            <Typography color='#154D72' fontSize={'15px'} lineHeight={'22.5px'} fontWeight={700} sx={{marginTop:'10px'}}>
                קבצים מצוריפים
            </Typography>
            :
            <Typography color='#154D72' fontSize={'15px'} lineHeight={'22.5px'} fontWeight={700} sx={{marginTop:'10px'}}>
               לא נמצאו קבצים מצורפים
            </Typography>
            }
            
            <ImageList cols={3} rowHeight={164} gap={10}>
            {(data?.files || []).map((item, index) => {
                if (item.sufix === 'pdf') {
                    return (
                    <Button key={index} variant="outlined" onClick={() => handleDownload(item.base64)}>
                        קובץ PDF
                    </Button>
                    );
                } else if (item.fileName.includes('.mp4')) {
                    return (
                    <ImageListItem key={index}>
                        <video width="250" controls style={{ borderRadius: '15px' }}>
                        <source src={item.base64} type="video/mp4" />
                        הדפדפן שלך אינו תומך בנגן וידאו.
                        </video>
                    </ImageListItem>
                    );
                } else {
                    return (
                    <ImageListItem key={index}>
                        <img
                        src={item.base64}
                        alt={item.fileName}
                        width={70}
                        height={70}
                        style={{ borderRadius: '15px' }}
                        />
                    </ImageListItem>
                    );
                }
                })}

            </ImageList>
                
        </Box>
    );
};

export default FilesList;