import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'
import { AuthService } from '../services/auth.service'

interface AuthState {
  user: IUser | null
  setUser: (user: IUser | null) => void
  updateAvatar: (newAvatar: IAvatar) => void
  loading: boolean
  userExtId: string
  phone: string
  email: string
  name: string
  token: string
  clearAuth: () => void
  setEmail: (value: string) => void
  setUserExtId: (value: string) => void
  setPhone: (value: string) => void
  logout: () => void
  login: (data: ILogin) => Promise<IUserResponse>
  validationStepOne: () => Promise<IAuthStepOneAndTwoResponse>
  validationStepTwo: (token: string) => Promise<IAuthStepOneAndTwoResponse>
  validationStepThree: (obj: IAuthStepThree) => Promise<IAuthStepThreeResponse>
  restorePasswordStepOne: (phone: string) => Promise<ApiResponse>
  restorePasswordStepTwo: (token: string) => Promise<ApiResponse>
  restorePasswordStepThree: (password: string) => Promise<ApiResponse>
}

export const useAuth = create(
  persist(
    (set, get) => ({
      user:null,
      setUser:(user) => set({user}),
      updateAvatar: (newAvatar) => set((state) => ({
        user: state.user ? {
          ...state.user,
          avatar: newAvatar, 
        } : null,
      })),
      loading: false,
      userExtId: '',
      setUserExtId: (value) => set({ userExtId: value }),
      phone: '',
      setPhone: (value: string) => set({ phone: value }),
      email: '',
      setEmail: (value: string) => set({ email: value }),
      name: '',
      token:'',
      clearAuth: () => {
        set({
          userExtId:'',
          phone:'',
          email:'',
          name:'',
          token:''
        })
      },
      logout: () => {
        get().clearAuth();
        set({user:null})
      },
      login: async (data: ILogin) => {
        try {
          set({ loading: true })
          const response = await AuthService.login(data)
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', user: null, token:'', refresh_token:'',area:[]}
        } finally {
          set({ loading: false })
        }
      },

      validationStepOne: async () => {
        try {
          set({ loading: true })
          const response = await AuthService.validationStepOne({
            phone: get().phone,
            userExtId: get().userExtId,
          })
          if(response.status === 'success' && response.data) {
            set({name: response.data.name})
            return response
          }
          return response;
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', data: null}
        } finally {
          set({ loading: false })
        }
      },

      validationStepTwo: async (token: string) => {
        try {
          set({ loading: true, token: token })
          const response = await AuthService.validationStepTwo({
            token:token,
            phone: get().phone,
            exId: get().userExtId
          })
          return response;
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', data: null}
        } finally {
          set({ loading: false })
        }
      },
      validationStepThree: async (obj: IAuthStepThree) => {
        try {
          set({ loading: true })
          const response = await AuthService.validationStepThree({
            ...obj,
            token: get().token,
            exId: get().userExtId,
          })
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', data: null}
        } finally {
          set({ loading: false })
        }
      },
      restorePasswordStepOne: async (email: string) => {
        try {
          set({ loading: true, email: email })
          const response = await AuthService.restorePasswordStepOne({
            email: email
          })
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', data: null}
        } finally {
          set({ loading: false })
        }
      },
      restorePasswordStepTwo: async (token: string) => {
        try {
          set({ loading: true, token: token })
          const response = await AuthService.restorePasswordStepTwo({
            token: token,
            email: get().email
          })
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', data: null}
        } finally {
          set({ loading: false })
        }
      },
      restorePasswordStepThree: async (password: string) => {
        try {
          set({ loading: true })
          const response = await AuthService.restorePasswordStepThree({
            email: get().email,
            password: password,
            token: get().token,
          })
          return response
        } catch (e) {
          console.error('[ERROR AUTH SERIVEC]', e)
          return {status:'error', message:'שגיאה בתהליך הרשמה', data: null}
        } finally {
          set({ loading: false })
        }
      },
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<AuthState, AuthState>
  )
)
