// Global
import React, {
  FC,
  createContext,
  useState,
  useContext,
  ReactNode,
} from 'react'
import Modals from '../components/Modals'
import ModalWrapper from '../components/Modals/ModalWrapper'
import { Box, Button, Typography } from '@mui/material'

// Defines
interface ModalContextType {

  setPdfViwer: (bool: boolean) => void
  handlePdfViwer: (value: string) => void
  pdfLinkOrBase64: string
  notifyAlert: (product: IProduct) => void
}
const ModalContext = createContext<ModalContextType | null>(null)

// React hook
const useModals = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('Can not run without "ModalContext Provider"')
  }
  return context
}

interface ModalsProviderProps {
  children: ReactNode
}
const ModalsProvider: FC<ModalsProviderProps> = ({ children }) => {
  const [pdfLinkOrBase64, setPdfLinkOrBase64] = useState('')
  const [pdfViwer, setPdfViwer] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null)
  const [openModal, setOpenModal] = useState(false)

  const notifyAlert = (product: IProduct) => {
      setSelectedProduct(product)
      setOpenModal(true)
  }


  const handlePdfViwer = (value: string) => {
    setPdfLinkOrBase64(value)
    setPdfViwer(true)
  }


  const value = {
    setPdfViwer,
    handlePdfViwer,
    pdfLinkOrBase64,
    notifyAlert,
  }

  return (
    <ModalContext.Provider value={value}>
      <Modals.PdfViwer
        active={pdfViwer}
        setActive={setPdfViwer}
        pdfLinkOrBase64={pdfLinkOrBase64}
      />
        <ModalWrapper
            active={openModal} 
            setActive={setOpenModal}
            component={
                <Typography color='#E92081' fontWeight={700} lineHeight={'28px'}  fontSize={'20px'}>
                שימו לב! 
                </Typography>
            }
            marginBottom={'0px'}
        >
            <Box>
                <Typography color='#546D79' fontSize={'15px'} lineHeight={'22.5px'} fontWeight={400}>
                כמות ההזמנה המינימלית למוצר {selectedProduct?.title} הינה {selectedProduct?.minimumQuantity} {selectedProduct?.packName}
                </Typography> 
                <Button variant='contained' sx={{boxShadow:'0px 3px 10px 0px #187B814D', marginTop:'30px'}} fullWidth onClick={() => setOpenModal(false)}> 
                בחזרה להזמנה
                </Button>
            </Box>
        </ModalWrapper>
      {children}
    </ModalContext.Provider>
  )
}

export { useModals, ModalsProvider }
