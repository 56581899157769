// Global
import React, {
    FC,
    createContext,
    useState,
    useContext,
    ReactNode,
  } from 'react'
  import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Button } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

  // Defines
  interface ModalContextType {
    onSuccessNotify: (title: string) => void
    onErrorNotify: (title: string) => void
  }
  const NotifyContext = createContext<ModalContextType | null>(null)
  
  // React hook
  const useNotify = () => {
    const context = useContext(NotifyContext)
    if (!context) {
      throw new Error('Can not run without "NotifyContext Provider"')
    }
    return context
  }
  
  interface NotifyProviderProps {
    children: ReactNode
  }
  const NotifyProvider: FC<NotifyProviderProps> = ({ children }) => {
    const [successNotify, setSuccessNotify] = useState(false)
    const [openErrorNotify, setErrorNotify] = useState(false)
    const [successText, setSuccessText] = useState('')
    const [successErrorText, setErrorText] = useState('')
    const seconds = 5000
    const {pathname} = useLocation()
    const isWithoutBottomNavigation = pathname?.includes('serviceDocumentPage') 

    const onSuccessNotify = (title: string) => {
        setSuccessText(title)
        setSuccessNotify(true)
        setTimeout(() => {
            setSuccessText('')
            setSuccessNotify(false)
        },seconds + 2000)
    } 

    const onErrorNotify = (title: string) => {
        setErrorText(title)
        setErrorNotify(true)
        setTimeout(() => {
            setErrorText('')
            setErrorNotify(false)
        },seconds + 2000)
    }

    const value = {
        onSuccessNotify,
        onErrorNotify
    }
  
    return (
      <NotifyContext.Provider value={value}>
        <Snackbar
            open={successNotify}
            autoHideDuration={seconds}
            onClose={() => setSuccessNotify(false)}
            message={successText}
            sx={{
              borderRadius:'16px',
              bottom: isWithoutBottomNavigation ? '20px' :'110px',
              '& .MuiPaper-root': {
                backgroundColor: '#E6F2F9',
                color: '#000',
                borderRadius: '8px',
                padding: '8px 16px',
                boxShadow: '0px 3px 10px 0px ##E6F2F9', 
              },
            }}
        />
        <Snackbar
            open={openErrorNotify}
            autoHideDuration={seconds}
            onClose={() => setErrorNotify(false)}
            message={successErrorText}
            color='error'
            sx={{
              borderRadius:'16px',
              bottom:'110px',
              '& .MuiPaper-root': {
                borderRadius:'16px',
                backgroundColor: '#FFDDE8',
                color: '#E92081',
                padding: '8px 16px',
                boxShadow: '0px 3px 10px 0px #FFDDE8',
              },
            }}
        />
        {children}
      </NotifyContext.Provider>
    )
  }
  
  export { useNotify, NotifyProvider }
  