import React from 'react';
import { Box, Typography } from '@mui/material';
import useDataCatalog from '../../hooks/useDataCatalog';
import CatalogCard from './CatalogCard';
import { useCatalog } from '../../store/catalog.store';
import { themeColors } from '../../styles';
import CatalogCardSkeleton from './CatalogCardSkeleton';

const CatalogList = () => {
    const { data, isLoading } = useDataCatalog();
    const { search, selectedCategories, openFavorite, favorites } = useCatalog();


    if (isLoading) {
        return (
            <Box>
                {[...Array(5)].map((_, index) => (
                    <CatalogCardSkeleton key={index} />
                ))}
            </Box>
        );
    }

    return (
        <Box>
            {data?.map((item, key) => {

                const filteredProducts = item.products?.filter((prod) => {
                    const searchTrimmed = search?.toLowerCase().trim() || '';
                    const matchesSearch =
                        prod.sku?.toLowerCase().includes(searchTrimmed) ||
                        prod.title?.toLowerCase().includes(searchTrimmed);

                    const matchesCategory =
                        selectedCategories.length === 0 || 
                        selectedCategories.includes(item.category);

                    return matchesSearch && matchesCategory;
                });


                const productsToDisplay = openFavorite
                    ? favorites.filter((fav) =>
                          filteredProducts.some((prod) => prod.id === fav.id)
                      )
                    : filteredProducts;


                const shouldShowCategory =
                    filteredProducts.length > 0 || selectedCategories.length === 0;

                return shouldShowCategory ? (
                    <Box key={key} sx={{ margin: '20px 0', marginBottom:'60px' }}>
                        <Typography
                            fontWeight={700}
                            fontSize={'15px'}
                            lineHeight={'21px'}
                            color={themeColors.title}
                            sx={{ padding: '0 10px' }}
                        >
                            {item.category}
                        </Typography>
                        {productsToDisplay.map((prod) => (
                            <CatalogCard key={prod.id} item={prod} />
                        ))}
                    </Box>
                ) : null;
            })}
        </Box>
    );
};

export default CatalogList;

