export const ApiInterceptor = async <T>(url: string, options?: RequestInit): Promise<T> => {
    options = options || {};
    const isFormData = options.body instanceof FormData;
    const isPatchRequest = options.method?.toUpperCase() === 'PATCH';
    
    options.headers = {
        ...options.headers,
        ...(!isFormData && { 
            'Content-Type': isPatchRequest ? 'application/merge-patch+json' : 'application/json' 
        }),
    };
    
    options.credentials = 'include';
    
    let response = await fetch(`${process.env.REACT_APP_API}${url}`, options);
    
    const text = await response.text();
    
    // If no content, return an empty object (or adjust accordingly)
    if (!text) {
        return {} as T;
    }
    
    return JSON.parse(text) as T;
};
