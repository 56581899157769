import React, { useState } from 'react';
import { Box, Button, TextField, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { ServicesService } from '../../services/services.service';
import { useAuth } from '../../store/auth.store';
import { useNavigate, useParams } from 'react-router-dom';
import { useArea } from '../../store/area.store';
import { useService } from '../../store/service.store';
import { useNotify } from '../../provider/NotifyProvider';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CustomCircularProgress from '../Common/Preloader';
import URLS from '../../enums/route';
// Styled input to visually hide the file input element
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileUpload: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<File[]>([]);
    const [comment, setComment] = useState('');
    const navigate = useNavigate()
    const { choosedArea } = useArea();
    const { user } = useAuth();
    const { sku, serialNumber } = useParams();
    const { openText, setOpenText, selectedTags, clearTags } = useService(); 
    const {onErrorNotify,onSuccessNotify} = useNotify()

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        }
    };

    const handleFileDelete = (indexToDelete: number): void => {
        setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
    };

    const handleCreateService = () => {
        if(!user?.permission?.permissionDetaileds[0].isServiceable){
            onErrorNotify('אין הרשאה לפתוח קריאת שירות') 
            return 
        }
        try {
            setLoading(true)
            ServicesService.createService(files, user?.extId! , sku!, comment, choosedArea?.code!, selectedTags, serialNumber!).then((e) => {
                if (e?.status === 'success') {
                    onSuccessNotify(e.message);
                    clearTags()
                    navigate(URLS.SERVICE.LINK)
                } else {
                    onErrorNotify(e.message);
                }
                setLoading(false)
            });
        } catch(e) {
            console.log('[ERROR]',e)
        } finally {
            
            setOpenText(false)
        }
    };

    return (
        <>
            <Box sx={{paddingTop:'20px'}}> 
                {openText &&
                    <TextField
                       multiline
                       rows={2}
                       variant="outlined"
                       fullWidth
                       value={comment}
                       onChange={(e) => setComment(e.target.value)}
                   />
                }

                {files.length > 0 && (
                    <Box mt={2}>
                        <Typography fontSize={'15px'} color='#154D72' fontWeight={700} lineHeight={'22.5px'}>קבצים מצורפים</Typography>
                        <Box>
                            {files.map((file, index) => (
                                <Box sx={{ display: 'flex', alignItems: 'center' }} key={index}>
                                    <Typography>{`${index +1} קובץ`}</Typography>
                                    <IconButton onClick={() => handleFileDelete(index)}>
                                        <CloseIcon sx={{color:'#154D72'}}/>
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}

                <Button 
                    component="label" 
                    role={undefined} 
                    variant="outlined" 
                    tabIndex={-1} 
                    fullWidth
                    startIcon={
                        <AddBoxOutlinedIcon sx={{width:'25px', height:'25px'}}/>
                    }
                    sx={{
                        bgcolor:'white',
                        marginTop:'10px'
                    }}
                >
                    צרף קובץ, ווידאו או תמונה
                    <VisuallyHiddenInput
                        type="file"
                        accept="image/*,video/*,application/pdf"
                        onChange={handleFileChange}
                        multiple
                    />
                </Button>
                <Typography sx={{marginTop:'5px'}} color='#154D72' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>נדרש להוסיף לפחות קובץ אחד.</Typography>
                <Typography sx={{marginTop:'5px'}} color='#154D72' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>ניתן להוסיף עד 5 קבצים.</Typography>
                <Typography color='#154D72' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>קבצים נתמכים: M4V, Mp4, MOV, PDF, JPG, PNG</Typography>
            </Box>
            {loading ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', margin:'20px 0'}}>
                    <CustomCircularProgress/>
                </Box>
            :
                <Box sx={{ display: 'flex', gap: '10px', marginTop:'20px' }}>
                    <Button
                        disabled={files.length === 0} 
                        variant="contained"
                        endIcon={
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Component 2">
                                <path
                                    id="Icon"
                                    d="M15.6314 7.63137C15.2353 7.23535 15.0373 7.03734 14.9631 6.80902C14.8979 6.60817 14.8979 6.39183 14.9631 6.19098C15.0373 5.96265 15.2353 5.76465 15.6314 5.36863L18.4697 2.53026C17.7165 2.18962 16.8804 2 16 2C12.6863 2 9.99998 4.68629 9.99998 8C9.99998 8.49104 10.059 8.9683 10.1702 9.42509C10.2894 9.91424 10.349 10.1588 10.3384 10.3133C10.3273 10.4751 10.3032 10.5612 10.2286 10.7051C10.1574 10.8426 10.0208 10.9791 9.7478 11.2522L3.49998 17.5C2.67156 18.3284 2.67156 19.6716 3.49998 20.5C4.32841 21.3284 5.67156 21.3284 6.49998 20.5L12.7478 14.2522C13.0208 13.9791 13.1574 13.8426 13.2949 13.7714C13.4388 13.6968 13.5249 13.6727 13.6867 13.6616C13.8412 13.651 14.0857 13.7106 14.5749 13.8297C15.0317 13.941 15.5089 14 16 14C19.3137 14 22 11.3137 22 8C22 7.11959 21.8104 6.28347 21.4697 5.53026L18.6314 8.36863C18.2353 8.76465 18.0373 8.96265 17.809 9.03684C17.6082 9.1021 17.3918 9.1021 17.191 9.03684C16.9626 8.96265 16.7646 8.76465 16.3686 8.36863L15.6314 7.63137Z"
                                    fill="#27C9D4"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                </g>
                            </svg>
                        }
                        fullWidth
                        onClick={handleCreateService}
                    >
                        פתח קריאת שירות
                    </Button>
                    <Button variant="contained">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Component 2">
                                <g id="Icon">
                                    <path d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </g>
                        </svg>
                    </Button>
                    <Button variant="contained">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Component 2">
                                <path
                                id="Icon"
                                d="M5 4.98963C5 4.01847 5 3.53289 5.20249 3.26522C5.37889 3.03203 5.64852 2.88773 5.9404 2.8703C6.27544 2.8503 6.67946 3.11965 7.48752 3.65835L18.0031 10.6687C18.6708 11.1139 19.0046 11.3364 19.1209 11.6169C19.2227 11.8622 19.2227 12.1378 19.1209 12.3831C19.0046 12.6636 18.6708 12.8862 18.0031 13.3313L7.48752 20.3417C6.67946 20.8804 6.27544 21.1497 5.9404 21.1297C5.64852 21.1123 5.37889 20.968 5.20249 20.7348C5 20.4671 5 19.9815 5 19.0104V4.98963Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                />
                            </g>
                        </svg>
                    </Button>
                </Box>
            }
      
        </>
    );
};

export default FileUpload;
