import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    IconButton,
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';

const AuthStepThreeForm = () => {
    const [error, setError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const {validationStepThree,login,setUser,clearAuth} = useAuth()
    const navigate = useNavigate()
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<IAuthStepThree>()

    const onSubmit = async (data: IAuthStepThree) => {
        try {
            const response = await validationStepThree(data)
            if(response.status === 'success'){
                navigate(URLS.AREA_PAGE.LINK)
                const response2 = await login(data)
                if(response2.status === 'success'){
                    setUser(response2.user)
                    navigate(URLS.HOME.LINK)
                    clearAuth()
                }
            } else {
                setError(response.message);
            }
        } catch(e) {
            setError('שגיאה');
        } 
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                אימייל
                </Typography>
                <Typography variant="body1" color='#436F7C'>
                יש להזין כתובת מייל
                </Typography>
                <FormControl
                    fullWidth
                    margin="normal"
                    sx={{ mt: "24px", mb: "0px" }}
                >
                    <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "מייל שדה חובה",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "מייל אינו תקין",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                placeholder="אימייל *"
                                error={!!errors.username}
                                helperText={errors.username?.message}
                            />
                        )}
                    />
                </FormControl>
                <Typography mt={'23px'} fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                סיסמא
                </Typography>
                <Typography variant="body1" color='#436F7C'>
                יש להשתמש ב-8 תווים לפחות, אותיות A-Z, מספרים 0-9,<br/>
                ורצוי לתבל בכמה תווים מיוחדים %^#!
                </Typography>
                <Box>
                    <FormControl
                        fullWidth
                        margin="normal"
                    >
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'סיסמא שדה חובה',
                                minLength: {
                                    value: 8,
                                    message: 'הסיסמא חייבת להיות לפחות 8 תווים',
                                },
                                validate: value => {
                                    const hasUpperCase = /[A-Z]/.test(value);
                                    const hasLowerCase = /[a-z]/.test(value);
                                    const hasNumber = /[0-9]/.test(value);
                                    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

                                    if (!hasUpperCase) {
                                        return 'הסיסמא חייבת לכלול לפחות אות גדולה אחת';
                                    }
                                    if (!hasLowerCase) {
                                        return 'הסיסמא חייבת לכלול לפחות אות קטנה אחת';
                                    }
                                    if (!hasNumber) {
                                        return 'הסיסמא חייבת לכלול לפחות ספרה אחת';
                                    }
                                    if (!hasSpecialChar) {
                                        return 'הסיסמא חייבת לכלול לפחות תו מיוחד אחד';
                                    }
                                    return true;
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="סיסמא"
                                    type={showPassword ? 'text' : 'password'}
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOffOutlinedIcon color="primary" />
                                                ) : (
                                                    <RemoveRedEyeOutlinedIcon color="primary" />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin="normal"
                    >
                        <Controller
                            name="secondPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'סיסמא שדה חובה',
                                validate: value =>
                                    value === getValues('password') || 'הסיסמאות אינן תואמות',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="אותה הסיסמא שוב"
                                    type={showPassword ? 'text' : 'password'}
                                    error={!!errors.secondPassword}
                                    helperText={errors.secondPassword?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOffOutlinedIcon color="primary" />
                                                ) : (
                                                    <RemoveRedEyeOutlinedIcon color="primary" />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Box>
                {error && (
                    <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
            <Box sx={{ bottom: 0, width: '100%' }}>
                <Button 
                variant='contained' 
                fullWidth 
                type='submit'
                sx={{marginBottom:'20px'}}
            >
                    המשך
                </Button>
            </Box>
        </form>
    );
};

export default AuthStepThreeForm;
