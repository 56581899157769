import React, { useState } from 'react';
import useDataPermission from '../../hooks/useDataPermission';
import { Box, Chip, FilledInput, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { usePermissionStore } from '../../store/permission.store';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { useAuth } from '../../store/auth.store';
import { HexColorPicker } from "react-colorful";
import Grid from '@mui/material/Grid2';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

const colors = ['#F58E08','#FC6C50','#F14483','#AB3CF0','#418CDD','#3149E5','#269E36','#1E676E','#546D79']

const Tags = () => {
    const {data, create} = useDataPermission()
    const { setChoosedPermission, choosedPermission, text, setText, openEdit, setOpenEdit,color, setColor} = usePermissionStore()
    const [openPicker, setOpenPicker] = useState(false)
    const {user} = useAuth()

    const createNewOne = () => {
        setOpenEdit(false)
        create(user!,text,color)
        setOpenPicker(false)
    }

    const handleOpenEdit = (item: IPermission) => {
        setColor(item.color)
        setChoosedPermission(item)
        setText(item.title)
        setOpenEdit(true)
    }

    return (
        <Box>
            <Grid container sx={{alignItems:'center'}} spacing={'5px'}>
                <Grid>
                    <IconButton onClick={() => setOpenEdit(!openEdit)}>
                    <AddCircleOutlineRoundedIcon sx={{color:'#2F4B6C', fontSize:'30px'}}/>
                </IconButton>
                </Grid>
                    {data?.map((item) => (
                        <Grid  key={item.id}>
                            <Chip
                                label={item?.title}
                                variant="outlined"
                                onClick={() => handleOpenEdit(item)}
                                clickable
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '19px',
                                    bgcolor: choosedPermission?.id === item.id ? item.color : 'transparent',
                                    color: choosedPermission?.id === item.id ? 'white' : item.color,
                                    border: choosedPermission?.id === item.id ? 'transparent' : `1px solid ${item.color}`,
                                    '&.MuiChip-clickable:hover': {
                                        bgcolor: item.color,
                                        color: 'white',
                                    },
                                }}
                            />
                        </Grid>
                    ))}
            </Grid>
        
            {openEdit &&
                <Box sx={{padding:'0 10px', mt:'25px'}}>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={'21px'} color='#154D72'>
                    שם התגית
                    </Typography>
                    <Typography fontWeight={400} fontSize={'13px'} color='#436F7C'>
                    בחרו שם לתגית
                    </Typography>
                    <TextField
                        sx={{
                            marginTop:'10px',
                        }}
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="create new item"
                                        onClick={createNewOne}
                                        edge="end"
                                    >
                                        <DoneRoundedIcon sx={{color:"#154D72"}}/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={'21px'} color='#154D72' sx={{mt:'30px'}}>
                        צבע תגית
                    </Typography>
                    <Typography fontWeight={400} fontSize={'13px'} color='#436F7C'>
                    בחרו שם לתגית
                    </Typography>
                    <Box sx={{display:'flex', gap:'5px', mt:'10px'}}>
       
                        {colors?.map((color) =>
                            <Box
                               onClick={() => setColor(color)}
                               sx={{
                                   cursor:'pointer',
                                   width: 30,      
                                   height: 30,      
                                   backgroundColor: `${color}`,  
                                   borderRadius: '50%',  
                                   display: 'inline-block',
                               }}
                           />
                        )}
                            <IconButton
                               onClick={() => setOpenPicker(!openPicker)}
                               sx={{
                                   width: 30,      
                                   height: 30,      
                                   borderRadius: '50%',  
                                   display: 'flex',
                                   border:'1px solid #8EA6B2',
                                   justifyContent:'center',
                                   alignItems:'center',
                                   bgcolor:color
                               }}
                           >
                                <img src={`${process.env.REACT_APP_MEDIA}/editIcon.svg`} width={15} height={15}/>
                            </IconButton>
                    </Box>
                    {openPicker &&
                    <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', padding:'10px'}}>
                        <Box sx={{position:'relative'}}>
                            <IconButton sx={{position:'absolute', right:-50}} onClick={() => setOpenPicker(false)}>
                                <CloseTwoToneIcon sx={{color:'#436F7C'}}/>
                            </IconButton>
                            <Box sx={{mt:'20px'}}>
                                <HexColorPicker color={color} onChange={setColor} />
                            </Box>
                        </Box>
                    </Box>
                    }
                </Box>
            }
        </Box>
    );
};

export default Tags;