import { Box, Button } from '@mui/material';
import React from 'react';
import useDataPermission from '../../hooks/useDataPermission';
import { usePermissionStore } from '../../store/permission.store';
import { useNotify } from '../../provider/NotifyProvider';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';

const Edit = () => {
    const {deletePermission, updatePermission} = useDataPermission()
    const {choosedPermission, setChoosedPermission, text, setText, setOpenEdit, color, setColor} = usePermissionStore()
    const {onSuccessNotify} = useNotify()
    const navigate = useNavigate()

    const deleteFunc = async () => {
        await deletePermission(choosedPermission?.id!)
        setChoosedPermission(null)
        setOpenEdit(false)
        setText('')
        setColor('#fff')
        navigate(URLS.BUSSNIES.LINK)
        setChoosedPermission(null)
     
    }

    const submit = () => {
        if(choosedPermission?.id){
            updatePermission({...choosedPermission, title: text, color: color})
            setOpenEdit(false)
            setText('')
            setColor('#fff')
            onSuccessNotify('התגית עודכנה בהצלחה.')
            navigate(URLS.BUSSNIES.LINK)
            setChoosedPermission(null)
        }
    }

    const cansle = () => {
        setChoosedPermission(null)
        setOpenEdit(false)
        setText('')
        setColor('#fff')
        navigate(URLS.BUSSNIES.LINK)
        setChoosedPermission(null)
    }

    return (
        <Box>
            {( choosedPermission && choosedPermission?.isEditable ) &&
                <>
                    <Box sx={{display:'flex', justifyContent:'center', gap:'10px'}}>
                        <Button variant='outlined' fullWidth sx={{bgcolor:'white'}} onClick={() => cansle()}>
                        ביטול
                        </Button>
                        <Button variant='contained' fullWidth onClick={() => submit()}>
                        אישור
                        </Button>
                    </Box>
                    <Button variant='outlined' fullWidth sx={{mt:'20px', bgcolor:'white', color:'#E92081', borderColor:'#E92081'}} onClick={() => deleteFunc()}>
                    מחק הרשאה
                    </Button>
                </>
            
            }

        </Box>
    );
};

export default Edit;