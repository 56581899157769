import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Link,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import OTPInput from '../../utils/OtpInput';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';
import { useNotify } from '../../provider/NotifyProvider';

const AuthStepTwoForm = () => {
    const [error, setError] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const { validationStepTwo, validationStepOne } = useAuth();
    const { handleSubmit, formState: { errors } } = useForm<IResetPasswordStepOne>();
    const {onErrorNotify, onSuccessNotify} = useNotify()

    const onSubmit = async (data: IResetPasswordStepOne) => {
        setError('');
        try {
            const response = await validationStepTwo(otp);
            if (response.status === 'success') {
                navigate(URLS.AuthStepThree.LINK);
                onSuccessNotify('אימות עבר בהצלחה!')
            } else {
                setError(response.message);
                onErrorNotify(response.message)
            }
        } catch (e) {
            setError('שגיאה');
        }
    };

    const handleRestore = async () => {
        try {
            const response = await validationStepOne()
            if(response?.status === 'success'){
                navigate(URLS.AuthStepTwo.LINK)
                onSuccessNotify('קוד סודי נשלח לטלפון')
            } else {
                onErrorNotify(response.message)
                setError(response.message);
            }
        } catch (e) {
            console.error("[ERROR]", e)
            onErrorNotify('שגיאה')
            setError('שגיאה');
        } 
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title} textAlign={'center'}>
                    קוד סודי
                </Typography>
                <Typography variant="body1" color='#436F7C' textAlign={'center'}>
                    שלחנו לכם קוד בהודעת SMS,<br/>
                    כתבו אותו כאן והמתינו לאישור.
                </Typography>
                <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', margin: '30px 0' }}>
                    <OTPInput otp={otp} setOtp={setOtp} />
                </Box>
                <Typography variant="body1" color='#436F7C' textAlign={'center'}>
                    לא קיבלתם סמס?
                    <Link underline="always" sx={{ display: 'inline', padding:'0 5px', cursor:'pointer' }} onClick={() => handleRestore()}>
                        <Typography variant="body1" component="span" color={themeColors.primary} style={{cursor:'pointer'}}>
                        נסו לשלוח שוב
                        </Typography>
                    </Link>
                </Typography>
                {error && (
                <Typography color="error" sx={{ textAlign: 'center', margin:"20px 0" }}>
                    {error}
                </Typography>
                )}
            </Box>
            <Box sx={{ bottom: 0, width: '100%' }}>
                <Button 
                    variant='contained' 
                    fullWidth 
                    type='submit' 
                    disabled={otp.length !== 5}
                    sx={{marginBottom:'20px'}}
                >
                    המשך
                </Button>
            </Box>
        </form>
    );
};

export default AuthStepTwoForm;
