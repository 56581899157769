export enum OrderStatus {
    Online = 'OnLine', // ALWAYS SHOW BUBBLES
    AvailableForAssignment = 'ניתן לשיבוץ!', // ALWAYS SHOW BUBBLES
    ApprovedForExecution = 'מאושר לבצוע!', // ALWAYS SHOW BUBBLES
    Delivered = 'סופקה', // SHOW TWO DAYS AGO 
    PartiallyDelivered = 'סופקה חלקית', // ALWAYS SHOW BUBBLES
    Canceled = 'מבוטלת', // NOT SHOWS
}

export const handleStatus = (status: string): string => {
    switch (status) {
        case OrderStatus.Online:
            return 'הזמנתך התקבלה';
        case OrderStatus.AvailableForAssignment:
            return 'הזמנתך בטיפול';
        case OrderStatus.ApprovedForExecution:
            return 'הזמנתך בדרך אליך';
        case OrderStatus.Delivered:
            return 'הזמנתך סופקה';
        case OrderStatus.PartiallyDelivered:
            return 'הזמנתך סופקה'; 
        case OrderStatus.Canceled:
            return 'הזמנתך בוטלה';
        default:
            return status;
    }
};