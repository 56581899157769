import React from 'react';
import { usePermissionStore } from '../../store/permission.store';
import { Box, FormGroup } from '@mui/material';

import Card from './Card';

const List = () => {
    const { choosedPermission } = usePermissionStore()
    
    return (
        <Box>
            {choosedPermission?.permissionDetaileds?.map((item) => 
            <FormGroup sx={{direction:'rtl', width:'100%'}} key={item.id}>
              <Card item={item} isEditable={choosedPermission?.isEditable}/>
            </FormGroup>
            )}
        </Box>
    );
};

export default List;