import { Box, FormControlLabel, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import useDataPermission from '../../hooks/useDataPermission';
import { useNotify } from '../../provider/NotifyProvider';

interface FormControlerProps {
    item: IPermissionDetailed;
    field: string;
    checkedValue: boolean;
    title: string;
    description: string;
    isEditable: boolean;
}

const FormControler: FC<FormControlerProps> = ({ item, checkedValue, field, title, description, isEditable }) => {
    const [checked, setChecked] = useState<boolean>(checkedValue);
    const { update } = useDataPermission();
    const {onErrorNotify} = useNotify()
    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        if (!isEditable){
            onErrorNotify('לא ניתן לערוך הרשאות בררת מחדל, ניתן ליצור משלך')
            return
        } 
        if (newAlignment !== null) {
            const isChecked = newAlignment === 'on';
            setChecked(isChecked);
            update(item.id, field, isChecked);
        }
    };

    return (
        <FormControlLabel
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                margin: '0px',
                mt: '25px',
            }}
            control={
                <ToggleButtonGroup
                    value={checked ? 'on' : 'off'}
                    exclusive
                    onChange={handleChange}
                    sx={{
                        gap: '10px',
                        direction: 'ltr',
                        borderRadius: '12px',
                        boxShadow: '0px 11px 24px 0px rgba(0, 51, 31, 0.10)',
                        padding: '5px',
                    }}
                >
                    <ToggleButton
                        value="on"
                        sx={{
                            border: 'none',
                            borderRadius: '12px 12px 12px 12px !important',
                            color: '#2F4B6C',
                            backgroundColor: checked ? '#e8f5e9' : 'transparent',
                            '&.Mui-selected': {
                                backgroundColor: '#1E536E',
                                color: 'white',
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: '#1E536E',
                            },
                        }}
                    >
                        <DoneRoundedIcon />
                    </ToggleButton>
                    <ToggleButton
                        value="off"
                        sx={{
                            border: 'none !important', 
                            outline: 'none !important', 
                            color: '#2F4B6C',
                            borderRadius: '12px !important',
                            backgroundColor: checked ? 'transparent' : '#f7f7f7',
                            '&.Mui-selected': {
                                backgroundColor: '#FF9DBA',
                                color: 'white',
                                border: 'none !important', 
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: '#FF9DBA',
                                border: 'none !important', 
                            },
                            '&:hover': {
                                border: 'none !important', 
                            },
                        }}
                    >
                        <CloseRoundedIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            }
            label={
                <Box sx={{ direction: 'ltr' }}>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={'21px'} color='#154D72'>
                        {title}
                    </Typography>
                    <Typography fontWeight={400} fontSize={'13px'} color='#436F7C'>
                        {description}
                    </Typography>
                </Box>
            }
        />
    );
};

export default FormControler;
