import { create } from 'zustand';
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware';

interface serviceState {
    selectedTags: ITagGroup[];
    setSelectedTags: (tag: ITagGroup) => void; 
    openText: boolean
    setOpenText: (openText: boolean) => void
    clearTags: () => void
    search: string,
    setSearch:(search:string) => void
}

export const useService = create(
  persist(
    (set) => ({
        search: '',
        setSearch:(search:string) => set({search}),
        openText:false,
        setOpenText:(openText) => set({openText}),
        selectedTags: [],
        clearTags: () => set({selectedTags:[]}),
        setSelectedTags: (tag: ITagGroup) =>
        set((state) => ({
          selectedTags: state.selectedTags.some(t => t.id === tag.id)
            ? state.selectedTags.filter(t => t.id !== tag.id) 
            : [...state.selectedTags, tag] 
        })),
    }),
    {
      name: 'service-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<serviceState, serviceState>
  )
);
