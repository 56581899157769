import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useDataDocumentItems from '../../hooks/useDataDocumentItems';
import IlustrateOne from './IlustrateOne';
import IlustrateTwo from './IlustrateTwo';
import moment from 'moment';
import { handleStatus, OrderStatus } from '../../enums/orderStatus';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const Info = () => {
    const { id } = useParams();
    const { data, isLoading } = useDataDocumentItems(id!);
    const randomIllustration = Math.random() > 0.5 ? 1 : 2;
    const navigate = useNavigate()
    const handleTitle = () => {
        if(
            data?.status === OrderStatus.Online  || 
            data?.status === OrderStatus.AvailableForAssignment ||
            data?.status === OrderStatus.ApprovedForExecution 
        ) {
            return (
                <Typography color="#154D72" fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                    הזמנה {data?.documentNumber}  התקבלה בהצלחה! 
                </Typography>
            )
        }   else if(
            data?.status === OrderStatus.Delivered  ||
            data?.status === OrderStatus.PartiallyDelivered  
        ) {
            return (
                <Typography color="#154D72" fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                     הזמנה {data?.documentNumber} סופקה
                </Typography>
            )
        }    else if(data?.status === OrderStatus.Canceled  ) {
            return (
                <Typography color="#154D72" fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                הזמנה {data?.documentNumber} בוטלה
                </Typography>
            )
        }
    }

    const handleDesc = () => {
        if(
            data?.status === OrderStatus.Online || 
            data?.status === OrderStatus.AvailableForAssignment ||
            data?.status === OrderStatus.ApprovedForExecution 
        ) {
            return (
                <Typography fontWeight={400} fontSize="15px" color="#546D79" sx={{ mt: '10px', padding:'0 25px' }}>
                <span style={{ fontWeight: 700 }}>שימו לב!</span> <br />
                ההזמנה הזו אושרה בתאריך {moment(data?.createdAt).format('DD-MM-YYYY')} ואינה ניתנת לשינוי דרך המערכת.<br />
                <span style={{ fontWeight: 700 }}>ההזמנה תגיע אליכם בתאריך {moment(data?.deliveryDate).format('DD-MM-YYYY')}.</span> <br />
                נתקלתם בבעיה? <a href="tel:0733743750" style={{ textDecoration: 'underline', color: '#546D79' }}>צרו קשר</a> לאפשרויות נוספות.
                </Typography>
            )
        } else if(
            data?.status === OrderStatus.Delivered ||
            data?.status === OrderStatus.PartiallyDelivered
        ) {
            return (
                <Box sx={{ mt: '10px' }}>
                    <Typography color="#436F7C" fontSize={'13px'} fontWeight={400}>
                        ההזמנה הזו סופקה בתאריך {moment(data?.deliveryDate).format('DD-MM-YYYY')}
                    </Typography>

                    <Box sx={{ marginTop: '20px' }}>
                        {!isLoading && !data?.questionDoc && (
                            <>
                                {randomIllustration === 1 ? <IlustrateOne /> : <IlustrateTwo />}
                                <Divider />
                            </>
                        )}
                    </Box>
                </Box>
            )
        } else if(data?.status === OrderStatus.Canceled) {
            return (
                <Box sx={{ mt: '10px' }}>
                    <Typography color="#436F7C" fontSize={'13px'} fontWeight={400}>
                        ההזמנה הזו מבוטלת 
                    </Typography>
                    <Box sx={{ marginTop: '20px' }}>
                        {!isLoading && !data?.questionDoc && (
                            <>
                                {randomIllustration === 1 ? <IlustrateOne /> : <IlustrateTwo />}
                                <Divider />
                            </>
                        )}
                    </Box>
                </Box>
            )
        } 
    }

    return (
        <Box>
            <Box sx={{display:"flex", alignItems:'center', mb:'10px'}} onClick={() => navigate(-1)}>
                <IconButton>
                    <ArrowForwardIosRoundedIcon sx={{color:'#154D72'}}/>
                </IconButton>
                <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                חזרה
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '2px', padding: '0 10px' }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Component 2">
                        <path
                            id="Icon"
                            d="M20.5 7.27774L12 12M12 12L3.49997 7.27774M12 12L12 21.5M21 16.0585V7.94144C21 7.5988 21 7.42747 20.9495 7.27468C20.9049 7.1395 20.8318 7.01542 20.7354 6.91073C20.6263 6.79239 20.4766 6.70919 20.177 6.54279L12.777 2.43168C12.4934 2.27412 12.3516 2.19534 12.2015 2.16445C12.0685 2.13712 11.9315 2.13712 11.7986 2.16445C11.6484 2.19534 11.5066 2.27412 11.223 2.43168L3.82297 6.54279C3.52345 6.70919 3.37369 6.79239 3.26463 6.91073C3.16816 7.01542 3.09515 7.1395 3.05048 7.27468C3 7.42748 3 7.5988 3 7.94144V16.0585C3 16.4012 3 16.5725 3.05048 16.7253C3.09515 16.8605 3.16816 16.9846 3.26463 17.0893C3.37369 17.2076 3.52345 17.2908 3.82297 17.4572L11.223 21.5683C11.5066 21.7259 11.6484 21.8046 11.7986 21.8355C11.9315 21.8629 12.0685 21.8629 12.2015 21.8355C12.3516 21.8046 12.4934 21.7259 12.777 21.5683L20.177 17.4572C20.4766 17.2908 20.6263 17.2076 20.7354 17.0893C20.8318 16.9846 20.9049 16.8605 20.9495 16.7253C21 16.5725 21 16.4012 21 16.0585Z"
                            stroke="#2F4B6C"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Icon_2"
                            d="M16.5 9.5L7.5 4.5"
                            stroke="#2F4B6C"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </svg>
                    {handleTitle()}
            </Box>
            <Box sx={{ padding: '0 10px' }}>
                {handleDesc()}
            </Box>
        </Box>
    );
};

export default Info;
