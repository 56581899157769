import React, { FC, useEffect, useState } from 'react';
import { Box, Card, Typography, Collapse, Button, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, Chip, Backdrop } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useDataPermission from '../../hooks/useDataPermission';
import { Controller, useForm } from 'react-hook-form';
import useDataArea from '../../hooks/useDataArea';
import useDataCompanyUsers from '../../hooks/useDataCompanyUsers';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import ChipSelector from '../Common/ChipSelector';
import FreeFieldCreate from './FreeFieldCreate';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useAuth } from '../../store/auth.store';
import ModalWrapper from '../Modals/ModalWrapper';


interface UserCardProps {
    item: IUser
}

interface IFormInput {
    fullName: string;
    areas: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;



const UserCard:FC<UserCardProps> = ({item}) => {
    const [active, setActive] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [openEdit, setOpenEdit] = useState(false)
    const navigate = useNavigate()
    const { data:areas } = useDataArea();
    const {update,unpublishUser} = useDataCompanyUsers()
    const {data: Tags} = useDataPermission()
    const [selectedPermission, setSelectedPermission] = useState<IPermission | null>(item.permission)
    const {user} = useAuth()
    const isAllowEdit = user?.permission?.permissionDetaileds[0]?.isArchitecture
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<IFormInput>({
        defaultValues: {
            fullName: '',
            areas: [],
        },
    });

    const onSubmit = (formData: IFormInput) => {
        setOpenEdit(false)
        update(formData.fullName,formData.areas,item.id,selectedPermission)
    };

    const handleUnpublish = () => {
        unpublishUser(item.id)
        setOpenEdit(false)
        setExpanded(false)
        setActive(false)

    }

    useEffect(() => {
        if (openEdit) {
            setValue('fullName', item?.name || ''); 
            const areaCodes = item?.areaPermissions?.map(permission => permission.area.code) || [];
            setValue('areas', areaCodes);
        }
    }, [openEdit, item, setValue]);


    return (
        <>
        <Card sx={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '10px 0', padding: '10px' }} key={item.id}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleExpandClick}>
                    <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                        <Box>
                            {!openEdit &&
                                <Box sx={{display:'flex', gap:'10px'}}>
                                    <Typography color='#154D72' fontWeight={700} lineHeight={'21px'} fontSize={'15px'}>{item?.name}</Typography>
                                    {item?.permission &&
                                        <Chip
                                            label={item?.permission?.title}
                                            sx={{
                                                padding:'0px',
                                                height:'18px',
                                                fontWeight:700,
                                                fontSize:'10px',
                                                lineHeight:'11.2px'
                                            }}
                                            variant={'filled'} 
                                            style={{ backgroundColor: item?.permission?.color , color: 'white' }} 
                                        />
                                    }
                                </Box>
                            }
                   
                            {!expanded &&
                                <>
                                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>{item?.phone}  {`${item?.email ? `· ${item?.email}`:''}`}</Typography>
                                </>
                            }
                        </Box>
                        {user?.permission?.permissionDetaileds[0]?.isAccounting &&
                          <>
                            {openEdit ?
                            <Box sx={{display:'flex', gap:'10px', mb:'20px'}}>
                                <Button 
                                    variant='text'
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        setOpenEdit(false);
                                    }}
                                    sx={{
                                        height:'35px'
                                    }}
                                >
                                ביטול
                                </Button>
                                <Button 
                                    onClick={() => setActive(true)}
                                    variant='outlined'
                                    color='info'
                                    endIcon={
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Component 3">
                                            <g id="Icon">
                                                <path 
                                                d="M3 6.5H21H3Z" 
                                                fill="#FF32BB" 
                                                />
                                                <path 
                                                d="M16 6.5V5.7C16 4.5799 16 4.01984 15.782 3.59202C15.5903 3.21569 15.2843 2.90973 14.908 2.71799C14.4802 2.5 13.9201 2.5 12.8 2.5H11.2C10.0799 2.5 9.51984 2.5 9.09202 2.71799C8.71569 2.90973 8.40973 3.21569 8.21799 3.59202C8 4.01984 8 4.5799 8 5.7V6.5M10 12V17M14 12V17M3 6.5H21M19 6.5V17.7C19 19.3802 19 20.2202 18.673 20.862C18.3854 21.4265 17.9265 21.8854 17.362 22.173C16.7202 22.5 15.8802 22.5 14.2 22.5H9.8C8.11984 22.5 7.27976 22.5 6.63803 22.173C6.07354 21.8854 5.6146 21.4265 5.32698 20.862C5 20.2202 5 19.3802 5 17.7V6.5" 
                                                stroke="#FF32BB" 
                                                strokeWidth="2" 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                />
                                            </g>
                                            </g>
                                        </svg>
                                    }
                                    sx={{
                                        height:'35px'
                                    }}
                                >
                                מחיקת עובד
                                </Button>
                                <Button 
                                    variant='contained'
                                    type='submit'
                                    endIcon={
                                        <CheckCircleOutlineRoundedIcon sx={{ height: '25px', width: '25px' }} />
                                    }
                                    sx={{
                                        height:'35px'
                                    }}
                                >
                                שמירה
                                </Button>
                            </Box>
                            :
                            <>
                            {isAllowEdit &&
                               <Box sx={{display:'flex', gap:'5px', cursor:'pointer'}} onClick={(e) => {
                                    e.stopPropagation(); 
                                    setExpanded(true);
                                    setOpenEdit(!openEdit);
                                }}>
                                    {expanded && (
                                        <Typography fontWeight={700} fontSize={15} color='primary' lineHeight={'21px'}>
                                            עריכה
                                        </Typography>
                                    )}
                                    <img src={`${process.env.REACT_APP_MEDIA}/editBus.svg`} width={'24px'} height={'24px'}/>
                                </Box>
                            }
                         
                            </>
                      
                            }
                          </>
                        }
                      
                    
        
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ padding: '10px' }}>
                        <Grid container spacing={2} direction="column">
                            <Grid   container justifyContent="flex-start" alignItems="center">
                                <Grid  size={2.5}>
                                <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                שם מלא
                                </Typography>
                                </Grid>
                                <Grid  size={9.5}>
                                    {openEdit ?
                                        <Controller
                                            name="fullName"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField {...field} error={!!errors.fullName} helperText={errors.fullName ? 'full name is required' : ''} />
                                            )}
                                            rules={{ required: 'fullName is required' }}
                                        />
                                    :
                                        <Typography variant="body1"  color='#546D79'>{item?.name}</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid   container justifyContent="flex-start" alignItems="center">
                                <Grid  size={2.5}>
                                    <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                    הרשאה
                                    </Typography>
                                </Grid>
                                <Grid  size={9.5}>
                                    {item?.permission ?
                                         <Chip
                                            label={item?.permission?.title}
                                            sx={{
                                                padding:'0px',
                                                height:'28px',
                                                fontWeight:700,
                                                fontSize:'12px',
                                                lineHeight:'16.8px'
                                            }}
                                            variant={'filled'} 
                                            style={{ backgroundColor: item?.permission?.color , color: 'white' }} 
                                        />
                                    :
                                        <Typography variant="body1"  color='#546D79'>{'אין הרשאה'}</Typography>
                                    
                                    }
                               
                                </Grid>
                            </Grid>
                            <Grid   container justifyContent="flex-start" alignItems="center">
                                <Grid  size={2.5}>
                                    <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                    מייל
                                    </Typography>
                                </Grid>
                                <Grid  size={9.5}>
                                    <Typography variant="body1"  color='#546D79'>{item?.email ?? '-'}</Typography>
                                </Grid>
                            </Grid>
                            <Grid   container justifyContent="flex-start" alignItems="center">
                                <Grid  size={2.5}>
                                <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                    טלפון
                                </Typography>
                                </Grid>
                                <Grid  size={9.5}>
                                    <Typography variant="body1" color='#546D79'>{item.phone ?? '-'}</Typography>
                                </Grid>
                            </Grid>
                            {!user?.isPayCustomer &&
                               <Grid  container justifyContent="flex-start" alignItems="center">
                                    <Grid  size={2.5}>
                                
                                    <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                        שייוך לסניף
                                    </Typography>
                                    </Grid>
                                    <Grid  size={9.5}>
                                        {openEdit ?
                                            <FormControl 
                                                sx={{ 
                                                    m: 1, 
                                                    width: '100%',
                                                    maxWidth: '100%', 
                                                    padding:'0px',
                                                    margin:'0px',
                                                    '& .MuiOutlinedInput-root': {
                                                        width: '100%',
                                                        maxWidth: '235px', 
                                                    }
                                                }}
                                                >
                                                <Controller
                                                    name="areas"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            multiple
                                                            sx={{
                                                                border:'1px solid #80A8B6',
                                                                borderRadius:'14px',
                                                                width:'180px'
                                                            }}
                                                            MenuProps={{
                                                                BackdropComponent: (props) => <Backdrop {...props} sx={{ background:'none' }} />,
                                                                PaperProps: {
                                                                    sx: {
                                                                        borderBottomLeftRadius: '14px',
                                                                        borderBottomRightRadius: '14px',
                                                                    },
                                                                },
                                                            }}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                const {
                                                                    target: { value },
                                                                } = e;
                                                                setValue(
                                                                    'areas',
                                                                    typeof value === 'string' ? value.split(',') : value
                                                                );
                                                            }}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) =>
                                                                selected
                                                                    .map((code) => {
                                                                        const area = areas?.find((a: IArea) => a.code === code);
                                                                        return area?.title || '';
                                                                    })
                                                                    .join(', ')
                                                            }
                                                        >
                                                            {areas?.map((area: IArea) => (
                                                                <MenuItem key={area.code} value={area.code}>
                                                                    <Checkbox checked={field.value.includes(area.code)} />
                                                                    <ListItemText primary={area.title} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        :
                                            <Typography variant="body1" color='#546D79' fontSize={'13px'}>
                                                    {item?.areaPermissions
                                                    ?.map((permission) => {
                                                        const area = areas?.find((a: IArea) => a.code === permission.area.code);
                                                        return area?.title;
                                                    })
                                                    .join(', ') || 'כל הסניפים'}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {user?.isPayCustomer &&
                                <Grid  container justifyContent="flex-start" alignItems="center">
                                    <Grid  size={2.5}>
                                    <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                        שייוך לסניף
                                    </Typography>
                                    </Grid>
                                    <Grid  size={9.5}>
                                        <Typography variant="body1" color='#546D79' fontSize={'13px'}>
                                            {item?.company}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        
                            {item?.userFreeFields?.map((field) => (
                                <Grid container key={field?.id} spacing={'2px'}>
                                    <Grid  size={2.5}>
                                        <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                            {field?.keyword}
                                        </Typography>
                                    </Grid>
                                    <Grid  size={9.5}>
                                        <Typography color='#436F7C' fontWeight={400} lineHeight={'19px'} fontSize={'13px'}>  
                                            {field?.valueword}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            {openEdit &&
                                <FreeFieldCreate item={item}/>
                            }
                        </Grid>
                        {openEdit &&
                            <Box sx={{display:'flex', gap:'10px'}}>
                                <Typography align="right" color='#154D72' fontSize={'12px'} lineHeight={'19px'} fontWeight={700}>
                                הרשאה
                                </Typography>
                                <Grid container spacing={'5px'}>
                                    {Tags?.map((item) => 
                                        <Grid key={item.id} >
                                            <ChipSelector item={item} handleClick={(e) => setSelectedPermission(e)} isSelected={item.id === selectedPermission?.id}/>
                                        </Grid>
                                    )}
                                    <Grid>
                                        <Button 
                                            variant='text'
                                            onClick={() => navigate(URLS.PERMISSION.LINK)}
                                            startIcon={
                                                <svg width="13" height="13" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_928_23781)">
                                                        <path d="M7.49741 4.16663L5.83074 2.49996M1.03906 8.9583L2.44922 8.80161C2.6215 8.78247 2.70765 8.7729 2.78816 8.74683C2.8596 8.72371 2.92758 8.69103 2.99027 8.6497C3.06092 8.60311 3.12221 8.54182 3.24478 8.41925L8.74741 2.91663C9.20764 2.45639 9.20764 1.7102 8.74741 1.24996C8.28717 0.789726 7.54098 0.789726 7.08074 1.24996L1.57812 6.75258C1.45554 6.87515 1.39425 6.93644 1.34766 7.00709C1.30633 7.06978 1.27365 7.13776 1.25053 7.20919C1.22446 7.28971 1.21489 7.37586 1.19575 7.54815L1.03906 8.9583Z" stroke="#27C9D4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_928_23781">
                                                            <rect width="10" height="10" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            }
                                            sx={{
                                                height:'20px',
                                                fontSize:'12px',
                                                fontWeight:700,
                                                lineHeight:'19px'
                                            }}
                                        >
                                            ערוך הרשאות 
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {
                            openEdit &&

                            <Typography fontSize={'13px'} fontWeight={400} lineHeight={'19px'} color='#80A8B6' sx={{paddingTop:'10PX'}}>
                            הרשאות מנהל סניף: ביצוע הזמנות, צפייה בהיסטוריית הזמנות, אישור קבלת מוצר וסגירת קריאה, פתיחת קריאות שירות
                            </Typography>
                        }
                    </Box>
                </Collapse>
            </form>
        </Card>
        <ModalWrapper 
            active={active} 
            setActive={setActive}
            component={
                <Typography color='info' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                   מחיקת עובד
                </Typography>
            }
        >
            <Box>
                <Typography color='#436F7C' fontSize={'15px'} fontWeight={700} lineHeight={'22.5px'}>
                שימו לב!
                </Typography>
                <Typography color='#546D79'>
                בלחיצה על <b style={{color:'#436F7C', fontSize:"15px", fontWeight:700}}>מחיקה</b>, העובד הנוכחי ימחק ולא ניתן יהיה לשחזרו.
                </Typography>
                <Button 
                    variant='outlined' 
                    color='info'
                    fullWidth
                    endIcon={
                        <img src={`${process.env.REACT_APP_MEDIA}/trshIcon.svg`} />
                    }
                    onClick={() => handleUnpublish()}
                    sx={{
                        mt:'30px',
                    }}
                >
                מחיקה
                </Button>
                <Button
                    onClick={() => setActive(false)}
                    sx={{
                        mt:'20px'
                    }}
                    fullWidth
                >
                חזרה 
                </Button>
            </Box>
        </ModalWrapper>
        
        </>

    );
};

export default UserCard;