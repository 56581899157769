import { BottomNavigation, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useProduct } from '../../store/product.store';
import { useCart } from '../../store/cart.store';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useModals } from '../../provider/ModalProvider';

const FooterItemAddToCart = () => {
    const {selectedProduct} = useProduct()
    const {getCartItem, increaseCart, decreaseCart,addToCart,changeQuantity} = useCart()
    const cart = getCartItem(selectedProduct!)
    const {notifyAlert} = useModals()

    const handleIncrase = () => {
        if(!cart && selectedProduct){
            addToCart(selectedProduct)
        } else {
            increaseCart(selectedProduct?.sku!)
        }
    }

    return (
        <BottomNavigation
            showLabels
            sx={{
                height: '120px',
                padding: '10px 0 40px 0px',
                background: 'var(--white-base-cards, #FFF)',
                boxShadow: '0px -2px 7px 0px rgba(0, 0, 0, 0.10)',
                display:'flex', 
                justifyContent:'center',
                alignItems:'center',
                gap:'10px',
                '@supports (color: display-p3)': {
                    background: 'var(--white-base-cards, color(display-p3 1 1 1))',
                    boxShadow: '0px -2px 7px 0px color(display-p3 0 0 0 / 0.10)'
                }
            }}
        >
            <Box sx={{display:'flex', gap:'10px', bgcolor:'#F5F7FA', justifyContent:'center', alignItems:'center', borderRadius:'10px'}}> 
                <Button onClick={() => decreaseCart(selectedProduct?.sku!)}>
                    <RemoveIcon sx={{fontSize:'30px'}}/>
                </Button>
                <Box sx={{display:'flex', gap:'10px'}}>

                    {cart?.product?.isEditable ?
                        <input type='number' value={cart?.quantity} onChange={(e) => changeQuantity(cart.sku,+e.target.value, notifyAlert)} style={{width:'40px', textAlign:'center', border:'none', backgroundColor:'#F5F7FA', fontSize:'16px', color:'#154D72'}}/>                    
                    :
                        <Typography color='#154D72' fontWeight={700} lineHeight={'18px'} letterSpacing={'0.3px'}> 
                            {cart?.quantity ?? '0'}
                        </Typography>
                    }
          
                    <Typography color='#154D72' fontWeight={700} lineHeight={'18px'} letterSpacing={'0.3px'}>
                        {selectedProduct?.packName}
                    </Typography>
                </Box>
 
                <Button onClick={() => handleIncrase()}>
                    <AddIcon sx={{fontSize:'30px'}}/>
                </Button>
            </Box>
            <Typography
                color='#154D72'
                fontWeight={700}
                lineHeight={'18px'}
                letterSpacing={'0.3px'}
            >
                סה"כ {(cart?.quantity ?? 0) * (selectedProduct?.packQuantity ?? 1)}{' '}
                {selectedProduct?.packUnit ?? `יח'`} 
            </Typography>
        </BottomNavigation>
    );
};

export default FooterItemAddToCart;