import { Box, TextField, InputAdornment, Typography, Stack, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Import the icon you want to use
import React from 'react';
import { useCatalog } from '../../store/catalog.store';
import useDataCatalog from '../../hooks/useDataCatalog';
import ChipSelector from '../Common/ChipSelector';
import Grid from '@mui/material/Grid2';

const Filter = () => {
    const { search, setSearch } = useCatalog();
    
    return (
        <Box sx={{padding:'0 10px'}}>
            <TextField
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{fontSize:'30px', color:'#8EA6B2'}}/>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    bgcolor:'white',
                    borderRadius:'14px'
                }}
                placeholder="חפשו לפי מוצר..." 
            />



        </Box>
    );
};

export default Filter;
