import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    Link,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    IconButton,
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';
import CustomCheckbox from '../Common/CUstomCheckBox';
import Turnstile, { useTurnstile } from "react-turnstile";
import { useNotify } from '../../provider/NotifyProvider';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: 'none',
      fontSize: 11,
    },
  }));

const AuthStepOneForm = () => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [error, setError] = useState('')
    const {onErrorNotify, onSuccessNotify} = useNotify()
    const { validationStepOne, setUserExtId, setPhone } = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        watch,
    } = useForm<IAuthStepOne>({
        mode: 'onChange', 
    })

    const privacyChecked = watch('privacy', false);
    const onSubmit = async (data: IAuthStepOne) => {
        const isDev = process.env.NODE_ENV === 'development';
        if (!captchaToken && !isDev) {
            onErrorNotify('אימות קפצ׳ה נכשל');
            return;
        }
        setError('')
        try {
            setUserExtId(data?.userExtId)
            setPhone(data?.phone)
            const response = await validationStepOne()
            if(response?.status === 'success'){
                navigate(URLS.AuthStepTwo.LINK)
                onSuccessNotify('קוד סודי נשלח לטלפון')
            } else {
                setError(response.message);
                onErrorNotify(response.message)
            }
        } catch (e) {
            console.error("[ERROR]", e)
            setError('שגיאה');
            onErrorNotify('שגיאה')
        } 
    }
    
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                    הרשמה למערכת
                </Typography>
                <Typography variant="body1" color='#436F7C'>
                כתבו את
                <LightTooltip 
                    placement="top"
                    onClose={() => setOpen(false)}
                    open={open}
                    arrow
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    slotProps={{
                        popper: {
                        disablePortal: true,
                        },
                    }}
                    sx={{
                        bgcolor:'white',
                        fontSize:'15px',
                    }}
                    title={
                        <>
                        <Box sx={{display:'flex', gap:'1px'}}>
                            <Typography sx={{color:'#1E536E', fontSize:'20px', lineHeight:'28px', fontWeight:700}}>
                            איפה אני רואה את מס׳ הלקוח שלי?
                            </Typography>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <img src={`${process.env.REACT_APP_MEDIA}/contract.png`} width={'100%'}/>
                        </>
                    }
                >
                    <Link  underline="always" sx={{ display: 'inline', padding:'0 5px', cursor:'pointer' }} onClick={() => setOpen(!open)}>
                        <Typography variant="body1" component="span" color={themeColors.primary}>
                        מספר הלקוח ( ? )
                        </Typography>
                    </Link>
                </LightTooltip>
                שלכם והטלפון שבחרתם<br/>
                להשתמש בו מראש במעמד החתימה על חוזה הלקוח.
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'טלפון שדה חובה',
                                minLength: {
                                    value: 10,
                                    message: 'טלפון חייב להכיל לפחות 10 תווים',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: 'טלפון לא יכול להכיל יותר מ-11 תווים',
                                  },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="טלפון*"
                                    type="tel"
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="userExtId"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'קוד משתמש',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="מספר לקוח"
                                    type="text"
                                    error={!!errors.userExtId}
                                    helperText={errors.userExtId?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControlLabel
                        sx={{ marginTop: '10px' }}
                        control={
                            <Controller
                                name="privacy"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => <CustomCheckbox {...field}/>}
                            />
                        }
                        label={
                            <>
                                <Typography variant="body1" color='#436F7C'>
                                קראתי והסכמתי ל
                                <Link onClick={() => navigate(URLS.POLICY_PAGE.LINK)} underline="always" sx={{ display: 'inline', padding:'0 5px' }}>
                                    <Typography variant="body1" component="span" color={themeColors.primary}>
                                    תנאי השימוש
                                    </Typography>
                                </Link>
                                </Typography>
                            </>
                        }
                    />
                    {error && (
                        <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
                            {error}
                        </Typography>
                    )}
                </form>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Turnstile
                        sitekey={process.env.REACT_APP_CLOUDFLARE!}
                        onVerify={(token) => setCaptchaToken(token)}
                    />
                </Box>
            </Box>
      
            <Box sx={{ bottom: 0, width: '100%' }}>
                <Box sx={{ display: 'flex', gap: '5px', textAlign:'center', justifyContent:'center', marginBottom:'30px'}}>
                    <Typography variant="body1" color='#436F7C'>
                        שכחתם סיסמא?
                        <Link  underline="always" sx={{ display: 'inline', padding:'0 5px', cursor:'pointer' }} onClick={() => navigate(URLS.RESET_PASSWORD_STEP_ONE.LINK)}>
                            <Typography variant="body1" component="span" color={themeColors.primary}>
                            בואו נטפל בזה
                            </Typography>
                        </Link>
                    </Typography>
                </Box>
                <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid || !privacyChecked}
                    sx={{marginBottom:'20px'}}
                >
                    המשך
                </Button>
            </Box>
        </Box>
    );
};

export default AuthStepOneForm;
