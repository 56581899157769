import { Box, Typography } from '@mui/material';
import React from 'react';
import { useProduct } from '../../store/product.store';
import { useCart } from '../../store/cart.store';


const CatalogItem = () => {
    const {selectedProduct} = useProduct()
    const {getCartItem} = useCart()
    const cart = getCartItem(selectedProduct!)
    
    return (
        <Box>
            <Box sx={{padding:'10px'}}>
                <img src={selectedProduct?.image ? `${process.env.REACT_APP_MEDIA_BACKEND}/${selectedProduct.image.filePath}` : `${process.env.REACT_APP_MEDIA}/placeholder.png`} width={'100%'} style={{borderRadius:'15px'}}/>
                {selectedProduct?.KosherType &&
                <Box sx={{display:'flex', alignItems:'center', gap:'10px', margin:'10px 0'}}>
                    <Box sx={{bgcolor:'#B2C8D3', borderRadius:'15px', padding:'4px 15px', width:'100px', height:'55px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={`${process.env.REACT_APP_MEDIA}/${selectedProduct?.KosherType}.svg`} width={'90px'} height={'45px'}/>
                    </Box>
                    <Typography fontSize={'13px'} lineHeight={'19.px'} fontWeight={400} color='#546D79'>
                    כשרות בד״צ
                    </Typography>
                </Box>
                }
                <Box>
                    <Typography color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                        {selectedProduct?.title}
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
                        {selectedProduct?.unitQuantity} {selectedProduct?.unitname} · {selectedProduct?.inPack} {selectedProduct?.packUnit} {selectedProduct?.packName2}
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
                        מק"ט {selectedProduct?.sku} 
                    </Typography>
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: selectedProduct?.description!,
                        }}
                        sx={{
                            bgcolor: '#F5F7FA !important',
                            color: "#436F7C !important",
                            pt: '20px !important',
                            fontWeight: 400,
                            lineHeight: '19.5px !important',
                            fontSize: '13px !important',
                            mb:'150px',
                            '& span': {
                                color: "#436F7C !important",
                                lineHeight: '19.5px !important',
                                fontSize: '13px !important',
                                bgcolor: '#F5F7FA !important', 
                            },
                            '& div' : {
                                color: "#436F7C !important",
                                lineHeight: '19.5px !important',
                                fontSize: '13px !important',
                                bgcolor: '#F5F7FA !important', 
                            },
                            '& p': {
                                color: "#436F7C !important",
                                lineHeight: '19.5px !important',
                                fontSize: '13px !important',
                                bgcolor: '#F5F7FA !important', 
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CatalogItem;