import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { PermissionService } from '../services/permissions.service'
import { useNotify } from '../provider/NotifyProvider'

const fetchData = async (
  userId: string | number,
): Promise<IPermission[]> => {
  return await PermissionService.getPermissions(userId)
}

const useDataPermission = () => {
  const { user } = useAuth()
  const {onSuccessNotify} = useNotify()
  const { data, error, isLoading, mutate } = useSWR<IPermission[]>(
    `/client/permissions/${user?.id}`,
    () => fetchData(user?.id!)
  )

  const update = async (id:number, field: string, value: boolean) => {
    if(value) {
      await PermissionService.updatePermission(id,field,value)
      mutate()
    }
  }

  const create = async (user: IUser, title: string, color:string) => {
    await PermissionService.createPermission(user,title,color)
    mutate()
  }

  const deletePermission = async (id: number) => {
    await PermissionService.deletePermission(id)
    onSuccessNotify('ההרשאה נמחקה בהצלחה')
    mutate()
  }

  const updatePermission = async (obj: IPermission) => {
    await PermissionService.updateMainPermission(obj)
    mutate()
  }

  return {
    data,
    isLoading: isLoading,
    isError: error,
    update,
    create,
    mutate,
    deletePermission,
    updatePermission
  }
}

export default useDataPermission

