import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    IconButton,
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import OTPInput from '../../utils/OtpInput';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';
import useWebOTP from '../../hooks/useWebOTP';

const ResetPasswordStepTwoForm = () => {
    const [error, setError] = useState('')
    const [otp,setOtp] = useState('')
    const navigate = useNavigate()
    const { phone, restorePasswordStepOne, restorePasswordStepTwo } = useAuth()
    useWebOTP((codeFromSMS: string) => {
        alert(`SMS ${codeFromSMS}`)
        // The code is the full string from the SMS (e.g. "12345").
        // If your OTP length is 5, it should match directly.
        setOtp(codeFromSMS);
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IResetPasswordStepOne>()

    const onSubmit = async (data: IResetPasswordStepOne) => {
        setError('')
        try {
            const response = await restorePasswordStepTwo(otp)
            if(response.status === 'success') {
                navigate(URLS.RESET_PASSWORD_STEP_THREE.LINK)
            } else {
                setError(response.message);
            }

        } catch(e) {
            console.log('[ERROR]',e)
            setError('שגיאה');
        } 
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title} textAlign={'center'}>
                קוד סודי
                </Typography>
                <Typography variant="body1" color='#436F7C' textAlign={'center'}>
                שלחנו לכם קוד בהודעת SMS,<br/>
                כתבו אותו כאן והמתינו לאישור.
                </Typography>
                <Box sx={{display:'flex', textAlign:'center', justifyContent:'center', margin:'30px 0'}}>
                    <OTPInput otp={otp} setOtp={setOtp}/>
                </Box>
                <Box sx={{display:'flex', gap:'3px'}}>
                    <Typography>
                    לא קיבלתם סמס?
                    </Typography>
                    <Typography color='#00A293' sx={{textDecoration:'underline', cursor:'pointer'}} onClick={() => restorePasswordStepOne(phone)}>
                    נסו לשלוח שוב
                    </Typography>
                </Box>
                {error && (
                    <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
                    {error}
                </Typography>
                )}
            </Box>
            <Box>
                <Button variant='contained' fullWidth type='submit'>
                המשך
                </Button>
            </Box>
        </form>
    );
};

export default ResetPasswordStepTwoForm;