import React from 'react';

function useWebOTP(onSuccess: (code: string) => void) {
  React.useEffect(() => {
    // Feature detection
    // You can check for 'OTPCredential' in window or
    // check if (navigator.credentials && 'OTPCredential' in window)
    if ('OTPCredential' in window) {
      const abortController = new AbortController();

      (async () => {
        try {
          // Cast navigator.credentials to `any` to avoid the TS error.
          const content = await (navigator.credentials as any).get({
            // This property won't type-check normally, so we cast to `any`.
            otp: { transport: ['sms'] },
            signal: abortController.signal,
          });
          alert(JSON.stringify(content))
          // Also cast `content` as any or define a local type for the code
          if (content && (content as any).code) {
            onSuccess((content as any).code);
          }
        } catch (err) {
          console.error('Error in Web OTP', err);
        }
      })();

      return () => {
        abortController.abort();
      };
    }
  }, [onSuccess]);
}

export default useWebOTP;
