import { Backdrop, Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React from 'react';
import { themeColors } from '../../styles';
import AreaSelector from '../../utils/AreaSelector';
import { useArea } from '../../store/area.store';
import useDataArea from '../../hooks/useDataArea';
import { useAuth } from '../../store/auth.store';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';

const Choose = () => {
    const { choosedArea, setChoosedArea } = useArea();
    const { data } = useDataArea();
    const { user } = useAuth();
    const navigate = useNavigate()
    const isOwner = user!?.isOwner;

    const allowedAreaCodes = user!?.areaPermissions?.map(permission => permission.area.code) || [];

    const filteredData = isOwner ? data : data?.filter(item => allowedAreaCodes.includes(item.code));

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        const selectedArea = filteredData?.find(item => item.code === selectedValue) || null;
        setChoosedArea(selectedArea);
        navigate(URLS.HOME.LINK)
    };

    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <Box>
                <Typography textAlign={'center'} fontWeight={700} fontSize={'20px'}  color={themeColors.title}>
                נדרש לבחור אתר
                </Typography>
                <Select
                    displayEmpty
                    value={choosedArea?.code || ''}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        if (!selected) {
                        return <span style={{ color: '#9E9E9E' }}>בחירת אתר</span>;
                        }
                        const selectedArea = filteredData?.find(item => item.code === selected);
                        return selectedArea ? selectedArea.title : '';
                    }}
                    sx={{
                        mt: '20px',
                        width: '290px',
                        color: themeColors.title
                    }}
                    MenuProps={{
                        BackdropComponent: (props) => <Backdrop {...props} sx={{ background: 'none' }} />,
                        PaperProps: {
                        sx: {
                            borderBottomLeftRadius: '14px',
                            borderBottomRightRadius: '14px',
                        },
                        },
                    }}
                    >
                    {filteredData?.map((item, index) => (
                        <MenuItem value={item.code} key={index}>
                        {item.title}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    );
};

export default Choose;