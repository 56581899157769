import React, {
    FC,
    createContext,
    useContext,
    ReactNode,
    useEffect,
  } from 'react'
  import { useLocation, useNavigate } from 'react-router-dom'
  import { useAuth } from '../store/auth.store'
  import URLS from '../enums/route'
  import { ApiInterceptor } from '../services/api.interceptor'
  import { useNotify } from './NotifyProvider'
  
  interface ModalContextType {}
  
  const ModalContext = createContext<ModalContextType | null>(null)
  
  const useAuthProvider = () => {
    const context = useContext(ModalContext)
    if (!context) {
      throw new Error('Can not run without "Auth Context Provider"')
    }
    return context
  }
  
  interface AuthProviderProps {
    children: ReactNode
  }
  
  const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const { user, logout } = useAuth() 
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const isAuthorized = Boolean(user?.id)
    const { onErrorNotify } = useNotify()
  
    useEffect(() => {
      if (user) {
        checkPermissions()
        const permissionCheckInterval = setInterval(checkPermissions, 60000) // Every 1 min
  
        checkSessionExpiration() // Run session check on mount
        const sessionInterval = setInterval(checkSessionExpiration, 60000) // Every 1 min
  
        return () => {
          clearInterval(permissionCheckInterval)
          clearInterval(sessionInterval)
        }
      }
    }, [user])
  
    useEffect(() => {
      checkSessionExpiration() // Run on every render
    }, [])
  
    useEffect(() => {
      if (
        [
          URLS.AuthHome.LINK,
          URLS.AuthLogin.LINK,
          URLS.AuthStepOne.LINK,
          URLS.AuthStepTwo.LINK,
          URLS.AuthStepThree.LINK,
          URLS.RESET_PASSWORD_STEP_ONE.LINK,
          URLS.RESET_PASSWORD_STEP_TWO.LINK,
          URLS.RESET_PASSWORD_STEP_THREE.LINK,
          URLS.POLICY_PAGE.LINK,
        ].includes(pathname)
      ) {
        if (isAuthorized && pathname !== URLS.POLICY_PAGE.LINK && pathname !== URLS.AREA_PAGE.LINK && pathname !== URLS.AuthLogin.LINK && pathname !== URLS.AuthStepThree.LINK) {
          navigate(URLS.HOME.LINK)
        }
      } else {
        if (!isAuthorized) {
          navigate(URLS.AuthHome.LINK)
        }
      }
    }, [pathname, isAuthorized])
  
    /** Logout user */
    const logoutUser = () => {
      logout()
      localStorage.removeItem('lastLoginDate')
      navigate(URLS.AuthHome.LINK)
    }
  
    /** Check if user permissions changed in backend */
    const checkPermissions = async () => {
      if (!isAuthorized) return
  
      try {
        const response = await ApiInterceptor<{ permissionsMatch: boolean, success: boolean, isBlocked:boolean  }>('/auth/checkPermission', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ permission: user?.permission, areaPremission: user?.areaPermissions, phone: user?.phone, extId: user?.extId })
        })
        if (!response.permissionsMatch) {
          onErrorNotify('שונו הרשאות המשתמש, נא להתחבר מחדש')
          setTimeout(() => {
            logoutUser()
          }, 3000)
        }
        if(response.isBlocked){
          onErrorNotify(`שים לב, הנך מוגדר כלקוח לא פעיל`)
          setTimeout(() => {
            logoutUser()
          }, 3000)
        }
      } catch (error) {
        console.error('Failed to check permissions', error)
      }
    }
  
    const checkSessionExpiration = () => {
      const lastLogin = localStorage.getItem('lastLoginDate')

      if (!lastLogin) {
        return
      }
  
      const lastLoginTime = new Date(lastLogin).getTime()
      if (isNaN(lastLoginTime)) {
        console.warn('Invalid lastLoginDate format, resetting session')
        localStorage.removeItem('lastLoginDate')
        logoutUser()
        return
      }
  
      const currentTime = Date.now()
      const sessionDuration = 7 * 24 * 60 * 60 * 1000 
      if (currentTime - lastLoginTime >= sessionDuration) {
        onErrorNotify('התחברות פגה תוקף, נא להתחבר מחדש')
        setTimeout(() => {
          logoutUser()
        }, 3000)
      }
    }
  
    useEffect(() => {
      if (isAuthorized) {
        localStorage.setItem('lastLoginDate', new Date().toISOString())
      }
    }, [isAuthorized])
  
    return <ModalContext.Provider value={{}}>{children}</ModalContext.Provider>
  }
  
  export { useAuthProvider, AuthProvider }
  