import { ApiInterceptor } from "./api.interceptor";

export const ServicesService = {
    async getMachines(
        userExtId: string,
        area: string,
    ): Promise<IMachine[]> {
        const response = await ApiInterceptor<IMachine[]>(`/client/machines/${userExtId}/${area}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async getMachine(userExtId: string, sku: string): Promise<IProduct>{
        const response = await ApiInterceptor<IProduct>(`/client/machine/${userExtId}/${sku}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async getDocumentSevice(documentNumber: string){
        const response = await ApiInterceptor<IDocument>(`/client/service/${documentNumber}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async updateService(documentNumber:string, isDelete: boolean, comment: string): Promise<ApiResponse>{
        const response = await ApiInterceptor<ApiResponse>(`/client/service/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({documentNumber,isDelete,comment})
        });
        return response
    },

    async createService(files: File[], userExtId: string, sku: string, comment:string, areaCode:string, tags:ITagGroup[], serialNumber:string): Promise<ISendOrderResponse> {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files[]', file); 
          });
        formData.append('userExtId', userExtId);
        formData.append('sku', sku);
        formData.append('comment', comment);
        formData.append('areaCode', areaCode);
        formData.append('tags', JSON.stringify(tags));
        formData.append('serialNumber', serialNumber);
        const response = await ApiInterceptor<ISendOrderResponse>(`/client/sendService`, {
            method: 'POST',
            body: formData, 
        });
    
        return response;
    },

    async getLastServices(userExtId: string, areaCode: string, isAvaliable: boolean = false){
        const response = await ApiInterceptor<IDocument[]>(`/client/lastServices/${areaCode}/${userExtId}${isAvaliable ? '?type=avaliable' : ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

}