import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface usePermissionStoreState {
    choosedPermission: IPermission | null,
    setChoosedPermission: (choosedPermission: IPermission | null) => void
    text: string,
    setText: (text: string) => void
    openEdit: boolean
    setOpenEdit: (openEdit: boolean) => void
    color: string
    setColor: (color: string) => void

}

export const usePermissionStore = create(
  persist(
    (set, get) => ({
        choosedPermission: null,
        setChoosedPermission: (choosedPermission) => set({choosedPermission}),
        text: '',
        setText: (text) => set({text}),
        openEdit: false,
        setOpenEdit: (openEdit) => set({openEdit}),
        color: '#fff',
        setColor: (color) => set({color}),
    }),
    {
      name: 'permission-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<usePermissionStoreState, usePermissionStoreState>
  )
)
